import { formatDate } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { DistributorService } from "src/app/services/distributor.service";
import { UserService } from "src/app/services/user.service";
import { UtilityService } from "src/app/services/utility.service";
import { WorkorderService } from "src/app/services/workorder.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

@Component({
  selector: "app-upload-excel",
  templateUrl: "./upload-excel.component.html",
  styleUrls: ["./upload-excel.component.scss"],
})
export class UploadExcelComponent {
  file: any;
  extension: string;
  jsonContent: any;
  result: any;
  templateDownload: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public distributorService: DistributorService,
    public dialog: MatDialogRef<UploadExcelComponent>,
    private utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private workorderService: WorkorderService,
    private userService: UserService
  ) {

    this.templateDownload = this.getTemplateURL(this.data.caption != "Import Report" ? this.data.caption : this.data.response?.ProgramdetailId);
  }

  private templateMap: { [key: string]: string } = {
    "IMPORT BULK DISTRIBUTORS": "BulkDistributor.xlsx",
    "IMPORT BULK CONTRACTORS": "BulkContractor.xlsx",
    "IMPORT MIDSTREAM EQUIPMENT": "EquipmentImport.xlsx",
    "IMPORT WASHINGTON GAS ACCOUNTS": "WashingtonGasImport.xlsx",
    "Bulk Upload Store": "StoreImport.xlsx",
    "Bulk Upload SKUs": "SKUImport.xlsx",
    "FEE6TMHV23": "LTOImport.xlsx",
    "FEE6TMAP23": "MSApplianceImport.xlsx",
    "FEE6TMRT23" : "MSRetailImport.xlsx"
  };

  private basePath: string = "../../assets/" + this.utilityService.currentUtility.OfficeId +"/templateExcels/";

  getTemplateURL(caption: string): string {
    const fileName = this.templateMap[caption] || "";
    return fileName ? `${this.basePath}${fileName}` : "";
  }

  handleFiles(e) {
    this.file = e.target.files[0];
    this.extension = this.file.name.split(".").pop();
    let fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      this.result = fileReader.result;
    };
    let objContent = fileReader.readAsDataURL(this.file);
    ///----------------read all columns-----------------
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary", cellDates: true });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.jsonContent = XLSX.utils.sheet_to_json(ws, { dateNF: 'yyyy-mm-dd', raw: false });
    };
  }

  uploadDoc() {
    if (
      this.extension === "csv" ||
      this.extension === "xlsx" ||
      this.extension === "xls"
    ) {
      this.spinner.show();
      if (
        this.data.caption == "Bulk Upload Store" ||
        this.data.caption == "Bulk Upload SKUs"
      ) {
        this.importStoreSKU();
      }
      if (this.data.caption == "IMPORT MIDSTREAM EQUIPMENT" ||
        this.data.caption == "IMPORT BULK CONTRACTORS" ||
        this.data.caption == "IMPORT BULK DISTRIBUTORS" || this.data.caption == "IMPORT WASHINGTON GAS ACCOUNTS"
      ) {
        this.importEquipmentUser();
      } if (this.data.caption == "Import Report") {
        this.bulkMeasure();
      }
    } else {
      Swal.fire({
        text: "Please upload CSV/ Excel file only",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  closedialog() {
    this.dialog.close();
  }

  importStoreSKU() {
    if (this.data.caption == "Bulk Upload Store") {
      let invalidDistricts = "";
      let invalidDistrictsMessage = "";
      let missingdistrictsStoreNums = "";
      let missingdistrictsMessage = "";
      const districts = this.distributorService?.indDetail?.StoresDetails[0]?.Individuals?.Districts;
      this.jsonContent.forEach(data => {
        const foundDistrict = districts.find(district => district.Value?.toLowerCase() === data.district?.toLowerCase());
        if (!foundDistrict) {
          if (data.district?.length > 0) {
            if (!invalidDistricts.includes(data.district)) {
              const invalidDistrict = data.district + ', ';
              invalidDistricts += invalidDistrict;
            }
          } else {
            const missingdistrictStoreNum = data.storenumber + ', ';
            missingdistrictsStoreNums += missingdistrictStoreNum;
          }
        } else {
          data.district = foundDistrict.Id;
        }
      });
      if (invalidDistricts.length > 0) {
        invalidDistrictsMessage = `The following districts are not part of this office:
        <br> <strong>${invalidDistricts.substring(0, invalidDistricts.length - 2)}.</strong><br><br>`;
      }
      if (missingdistrictsStoreNums.length > 0) {
        missingdistrictsMessage = `The following store numbers do not have a district assigned: 
        <br> <strong>${missingdistrictsStoreNums.substring(0, missingdistrictsStoreNums.length - 2)}.</strong><br><br>`
      }
      if (invalidDistrictsMessage?.length > 0 || missingdistrictsMessage?.length > 0) {
        this.spinner.hide();
        Swal.fire({
          html: invalidDistrictsMessage + missingdistrictsMessage + 'Please correct and re-upload',
          icon: "error",
          confirmButtonText: "OK",
        });
        this.dialog.close();
        return;
      }
    }
    let body =
      this.data.caption == "Bulk Upload Store"
        ? {
          Utility: this.distributorService.selectedUtility,
          CompanyName: this.distributorService.selectedCompany,
          CompanyAbbr: this.distributorService.selectedCompanyAbbr,
          UserId: this.userService.dbBBCSId === '' ? this.utilityService.currentUtility.BBCSUserId : this.userService.dbBBCSId,
          Storejson: JSON.stringify(this.jsonContent),
        }
        : {
          Utility: this.distributorService.selectedUtility,
          UserId: this.userService.dbBBCSId === '' ? this.utilityService.currentUtility.BBCSUserId : this.userService.dbBBCSId,
          Skujson: JSON.stringify(this.jsonContent),
        };
    let isStore = this.data.caption == "Bulk Upload Store" ? true : false;
    this.distributorService.importStoreSKU(body, isStore).subscribe((res) => {
      if (res.Action) {
        if (this.data.caption == "Bulk Upload Store") {
          this.distributorService.getStoresOnCompany().subscribe((data) => {
            this.spinner.hide();
            Swal.fire({
              text: res.Message,
              icon: "success",
              confirmButtonText: "OK",
            });
            this.distributorService.indDetail.StoresDetails = data.StoresDetails;
            this.dialog.close(data);
          });
        } else {
          this.distributorService.getSKUOnDistributor().subscribe((data) => {
            this.spinner.hide();
            Swal.fire({
              text: res.Message,
              icon: "success",
              confirmButtonText: "OK",
            });
            this.dialog.close(data);
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          text: res.Message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.dialog.close();
        });
      }
    });
  }

  importEquipmentUser() {
    let body = {
      importJson: JSON.stringify(this.jsonContent),
      importType: this.data.caption
    };
    this.distributorService.importEquipmentUser(body).subscribe((res) => {
      this.spinner.hide();
      if (res.Action) {
        Swal.fire({
          text: res.Message,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          this.dialog.close();
        });
      } else {
        Swal.fire({
          text: res.Message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.dialog.close();
        });
      }
    });
  }

  bulkMeasure() {
    let FromDate;
    let ToDate;
    let Description;
    let TotalRebate;
    const sections = this.data.response?.SectionInfo;
    sections.forEach(section => {
      if (section?.SectionId === "INVOICEIMPORT") {
        const attributes = section?.DetailedInfo[0]?.Attributes;
        attributes.forEach(attr => {
          if (attr.AttributeId === 'AppStartDate') {
            FromDate = attr.SelectedValue != null ? formatDate(attr.SelectedValue, "dd-MM-yyyy", "en-US") : "";
          } if (attr.AttributeId === 'AppEndDate') {
            ToDate = attr.SelectedValue != null ? formatDate(attr.SelectedValue, "dd-MM-yyyy", "en-US") : "";
          } if (attr.AttributeId === 'Description') {
            Description = attr.SelectedValue;
          } if (attr.AttributeId === 'TotalRebate') {
            TotalRebate = attr.SelectedValue;
          }
        });
      }
    });
    this.result = this.result?.split(",")[1];
    let body = {
      File: this.file.name,
      Invoicejson: JSON.stringify(this.jsonContent),
      Content: this.result,
      Description: Description,
      FromDate: FromDate,
      ToDate: ToDate,
      TotalRebate: TotalRebate,
    };
    this.workorderService
      .saveMeasureSalesImport(
        body,
        this.data.response.WorkorderId,
        this.data.response.SubGroup,
        this.data.response.SubGroup1
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res.Action) {
          Swal.fire({
            text: res.Message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.dialog.close();
          });
        } else {
          Swal.fire({
            text: res.Message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.dialog.close();
          });
        }
      });
  }
}