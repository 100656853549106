import { Injectable } from '@angular/core';
import { Interaction } from '../models/interaction';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Workorder } from '../models/workorder';
import { Attachments } from '../models/attachments';
import { Measures, MeasureAttributes } from '../models/measures';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class WorkorderService {
  private woSearchWsUrl1 = environment.apiUrl + 'workorder/';
  private priscriptiveSuitUrl = environment.apiUrl + 'prescriptive/';
  private drActionUrl = environment.apiUrl;
  private qaWorkOrder = environment.apiUrl;
  currentWorkorder: Workorder;
  currentWorkorderID: string;
  interactions: Workorder[];
  programSponsorId: any;
  selectedExternalID: any;
  workorderStatusCode: any = 'schedule';
  currentIAWorkorderID: any;

  constructor(private http: HttpClient, private utilityService: UtilityService, private userService: UserService) { }

  public getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;
  }

  getFileTypesForUpload() {
    const searchUrl = this.woSearchWsUrl1 + "documents/filetypes/" + this.utilityService.currentUtility.OfficeId
      + '?code=' + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  saveDRworkorder(response, workorderID): Observable<any> {
    const searchUrl = this.woSearchWsUrl1 + "dr/saveworkorder/" + this.utilityService.currentUtility.OfficeId
      + '/' + workorderID + '?code=' + environment.apiCode;

    const body = response;
    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  saveWorkorder(response, workorderID, subgroup, subGroup1): Observable<any> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + "/patch/" + workorderID + "/user/" + BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole
      + '?code=' + environment.apiCode + '&SubGroup=' + subgroup + '&subGroup1=' + subGroup1 + "&org_cd=" + this.userService.orgCd;

    const body = response;
    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  savePSGWorkorder(response, workorderID): Observable<any> {

    const searchUrl = environment.apiUrl + "audit/" + this.utilityService.currentUtility.OfficeId
      + '/psgauditworkorder/update/' + workorderID + "/user/" + this.utilityService.currentUtility.BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole + '?code=' + environment.apiCode;

    const body = response;
    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  deletePSGRow(workorderID, lineItem, subGroup): Observable<any> {

    const searchUrl = environment.apiUrl + "workorder/" + this.utilityService.currentUtility.OfficeId
      + '/measure/' + workorderID + '/deleteappliance/' + lineItem + "/user/" + this.utilityService.currentUtility.LoggedInUserRole + '?SubGroup=' + subGroup + '&code=' + environment.apiCode;

    //const searchUrl = environment.apiUrl + "audit/" + this.utilityService.currentUtility.OfficeId
    //+ '/psgauditworkorder/' + workorderID  + "/deleteappliance/" + lineItem + "/user/" + this.utilityService.currentUtility.LoggedInUserRole + '?code=' + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  changeWorkorderStatus(workorderID, subgroup): Observable<any> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + "/statuschange/" + workorderID + "/user/" + BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole
      + '?code=' + environment.apiCode + '&SubGroup=' + subgroup;

    const body = '';
    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getProgramsAtEnrollmentLevel(enrollmentId): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + "programdetails/" + this.utilityService.currentUtility.OfficeId
      + '/' + enrollmentId + '?code=' + environment.apiCode + "&org_cd=" + this.userService.orgCd; 
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getAllProgramsForAllEnrollments(): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + "programdetails/" + this.utilityService.currentUtility.OfficeId
      + '/' + "ALL" + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  completeWorkorder(workorderID): Observable<any[]> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = environment.apiUrl + "workorder/" + this.utilityService.currentUtility.OfficeId
      + '/markcompleted/' + workorderID + "/user/" + BBCSUserId + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  returnToDEWorkorderService(workorderID): Observable<any[]> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = environment.apiUrl + "workorder/" + this.utilityService.currentUtility.OfficeId
      + '/returntode/' + workorderID + "/user/" + BBCSUserId + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  returnOffholdWorkorderService(workorderID): Observable<any[]> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = environment.apiUrl + "audit/" + this.utilityService.currentUtility.OfficeId
      + '/measureworkorder/offhold/' + workorderID + "/user/" + BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  InstallcompleteWorkorder(workorderID): Observable<any[]> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = environment.apiUrl + "workorder/" + this.utilityService.currentUtility.OfficeId + "/markinstallcomplete/" + workorderID + "/user/" + BBCSUserId + "?code=" + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  returnAssignWorkorderService(workorderID): Observable<any[]> {

    const searchUrl = environment.apiUrl + "audit/" + this.utilityService.currentUtility.OfficeId
      + '/measureworkorder/assign/' + workorderID + "/user/" + this.utilityService.currentUtility.BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  addAppliance(workorderID, subGroup1): Observable<any[]> {
    const searchUrl = environment.apiUrl + "dr/addnewappliance/" + this.utilityService.currentUtility.OfficeId
      + '/workorder/' + workorderID + '?SubGroup1=' + subGroup1 + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  addPsgAppliance(workorderID, SectionId, subGroup, subGroup1): Observable<any[]> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = environment.apiUrl + "workorder/" + this.utilityService.currentUtility.OfficeId
      + '/measure/' + workorderID + '/addappliance/' + SectionId + "/user/" + BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole + '?SubGroup=' + subGroup + '&SubGroup1=' + subGroup1 + '&code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  /* 
   addPsgAppliance(workorderID, SectionId,subGroup): Observable<any[]> {
      const searchUrl = environment.apiUrl + "audit/" + this.utilityService.currentUtility.OfficeId
        + '/psgauditworkorder/' + workorderID + '/addappliance/' + SectionId + "/user/" + this.utilityService.currentUtility.BBCSUserId +"/" + this.utilityService.currentUtility.LoggedInUserRole + '?code=' + environment.apiCode;
  
      return this.http.get<any[]>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => this.utilityService.handleError(err, this, []))
        );
    }
  */


  addApplianceDetails(workorderID, SectionId, subGroup, subGroup1): Observable<any[]> {
    const searchUrl = environment.apiUrl + "workorder/" + this.utilityService.currentUtility.OfficeId
      + '/measure/' + workorderID + '/addappliance/' + SectionId + "/user/" + this.utilityService.currentUtility.BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole + '?SubGroup=' + subGroup + '&SubGroup1=' + subGroup1 + '&code=' + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }


  uploadFile(fileName, contentBody, workorderID): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + "documents/upload/" + this.utilityService.currentUtility.OfficeId
      + '/id/' + workorderID + '?code=' + environment.apiCode;

    const body = contentBody;

    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  deleteFile(contentBody, workorderID): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + "deleteimage/" + this.utilityService.currentUtility.OfficeId
      + '/workorder/' + workorderID + '?code=' + environment.apiCode;

    const body = contentBody;

    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  getDocumentsForWorkorder(workorderID, lineItemId = null): Observable<any[]> {
    const searchUrl =
      lineItemId == null ? this.woSearchWsUrl1 + "documents/get/" + this.utilityService.currentUtility.OfficeId + "/id/" +
        workorderID + "?code=" + environment.apiCode
        : this.priscriptiveSuitUrl + "documents/get/" + this.utilityService.currentUtility.OfficeId + "/id/" +
        workorderID + "/" + lineItemId + "?code=" + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  deleteWorkorderDR(workorderID, response): Observable<any[]> {
    const searchUrl = environment.apiUrl + "dr/deleteappliance/" + this.utilityService.currentUtility.OfficeId
      + '/workorder/' + workorderID + '?code=' + environment.apiCode;

    const body = response;

    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getBBCSWorkorderDetail(workorderID, group, subgroup, customerID, subgroup1): Observable<any[]> {


    //  {{uri}}/api/workorder/:officeId/account/:customerId/ProgramSponsorId/:ProgramSponsorId/WorkorderId/:WorkorderID/?code={{account-onering-api-key}}&SubGroup=INSTALL&UserRole=ADMIN&bbcsUser=vaishal_1r
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId + "/account/" + customerID +
      "/ProgramSponsorId/" + this.utilityService.currentProgramSponsor.ProgramSponsorId + "/WorkorderId/" + workorderID + '?code=' +
      environment.apiCode + '&SubGroup=' + subgroup + '&SubGroup1=' + subgroup1 + '&UserRole=' + this.utilityService.currentUtility.LoggedInUserRole + '&bbcsUser=' + BBCSUserId +
      '&org_cd=' + this.userService.orgCd;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getQaWorkorder(workorderID): Observable<any[]> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl = this.qaWorkOrder + "qa/" + this.utilityService.currentUtility.OfficeId
      + '/workorder/' + workorderID + "/user/" + BBCSUserId + "/" + this.utilityService.currentUtility.LoggedInUserRole + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  createWorkorder(data, groupId = 'QA', subGroupId = 'QA'): Observable<any[]> {
    const createUrl = environment.apiUrl + "bbcs/" + this.utilityService.currentUtility.OfficeId
      + '/workorder/add/group/' + groupId + '/subgroup/' + subGroupId + '?code=' + environment.apiCode;

    return this.http.post<any>(createUrl, data, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getComparisonDetail(
    workorderID,
    IAWorkorder,
    subgroup,
    subgroup1,
  ): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId + "/compare/" + IAWorkorder + "/" +
      workorderID + "/user" + "/" + this.utilityService.currentUtility.LoggedInUserRole + "?code=" + environment.apiCode +
      "&SubGroup=" + subgroup + "&SubGroup1=" + subgroup1 + "&UserRole=" + this.utilityService.currentUtility.LoggedInUserRole + "&bbcsUser=" + this.utilityService.currentUtility.BBCSUserId + "&org_cd=ALL";

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  loadDynamicMenu(workorderID) {
    const searchUrl = this.woSearchWsUrl1 + "actions/" + this.utilityService.currentUtility.OfficeId
      + '/' + workorderID + '/role/' + this.utilityService.currentUtility.LoggedInUserRole + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getOnHoldReasons(status): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + "reasons/" + this.utilityService.currentUtility.OfficeId
      + '/status/' + status + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getCancelDeactivateReasons(status): Observable<any[]> {
    const searchUrl = this.woSearchWsUrl1 + "reasons/" + this.utilityService.currentUtility.OfficeId
      + '/status/' + status + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  cancelDeactivateWorkorder(workorderObj) {
    const searchUrl = this.woSearchWsUrl1 + "canceldeactivate/" + this.utilityService.currentUtility.OfficeId
      + '/id/' + workorderObj.workorderID + '?code=' + environment.apiCode;


    const body = {
      User: workorderObj.User,
      Reason: workorderObj.Reason,
      Status: workorderObj.Status
    };

    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  //to call the compass cancel workorder
  getCompassCancelDeactivateWorkorder(workorderId, userId, jobId) {

    const searchUrl = environment.apiUrl + "CompassIntegration/cancel/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderId + "/user/" + userId + "/" + jobId + "?code=" + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }
  //to call the compass cancel workorder -END


  pendHold(workorderId, woStatus, reasonCode, userId) {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + '/PendHold/' + workorderId + "/" + woStatus + "/" + reasonCode + "/user/" + userId + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  //to call the compass onhold - Start
  getCompassOnHold(workorderId, userId, jobId) {
    const searchUrl = environment.apiUrl + "CompassIntegration/onhold/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderId + "/user/" + userId + "/" + jobId + "?code=" + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }
  //to call the compass onhold - End

  //to call the compass offhold - Start
  getCompassOffHold(workorderId, userId, jobId) {
    const searchUrl = environment.apiUrl + "CompassIntegration/offhold/" + this.utilityService.currentUtility.OfficeId + "/workorder/" + workorderId + "/user/" + userId + "/" + jobId + "?code=" + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }
  //to call the compass offhold - End

  getWorkorderByCustomerId(customerID): Observable<Workorder[]> {

    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + '/account/' + customerID + '/programsponsorid/' + this.utilityService.currentUtility.ProgramsponsorId + '?code=' + environment.apiCode;
    return this.http.get<Workorder[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  public getEnrollmentAndWorkorderByPersonCompanyId(personCompanyId: string) {
    // {{uri}}/api/customer/:officeId/userRole/:userRole/:Id?code={{account-onering-api-key}}

    const searchUrl = environment.apiUrl + 'customer/' + this.utilityService.currentUtility.OfficeId
      + '/userRole/' + this.utilityService.currentUtility.LoggedInUserRole + '/' + personCompanyId + '?code=' + environment.apiCode + '&org_cd=' + this.userService.orgCd;


    return this.http.get<any>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }


  getWorkorderDetailsByWorkorderId(workorderID, customerID): Observable<Workorder[]> {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + '/account/' + customerID + '/programsponsorid/' + this.utilityService.currentUtility.ProgramsponsorId + '/workorderid/' + workorderID + '?code=' + environment.apiCode;

    return this.http.get<Workorder[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getMeasureAttributes(measureID): Observable<any> {
    const searchUrl = environment.apiUrl + 'workorder/' + this.utilityService.currentUtility.OfficeId
      + '/measures/' + measureID + '?code=' + environment.apiCode;
    return this.http.get<MeasureAttributes[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => err)
      );
  }

  getWorkorderMeasures(workorderID, workorderName): Observable<any> {

    const searchUrl = environment.apiUrl + 'workorder/' + this.utilityService.currentUtility.OfficeId
      + '/measures/' + workorderID + "/workordername/" + workorderName + '?code=' + environment.apiCode;

    return this.http.get<Measures[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [err])
      );
  }

  getWorkorderHistory(workorderID) {
    const searchUrl = environment.apiUrl + 'workorder/' + this.utilityService.currentUtility.OfficeId + '/statushistory/WorkorderID/'
      + workorderID + '?code=' + environment.apiCode;

    /* environment.apiUrl + 'workorderstatushistory/' + this.utilityService.currentUtility.OfficeId
     + '/workorderid/' + workorderID + '?code=' + environment.apiCode; */
    return this.http.get<Workorder[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  getWorkorderAttachments(workOrder) {

    this.selectedExternalID = workOrder.ExternalId;
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + '/externalid/' + this.selectedExternalID + '/attachments' + '?code=' + environment.apiCode;
    return this.http.get<Attachments[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  // openPdfFile(file){
  //   var arrBuffer = this.base64ToArrayBuffer(file.FileContent);
  //   var blob = new Blob([arrBuffer], { type: 'application/pdf' });
  //   const downloadURL = URL.createObjectURL(blob);
  //   /*   var a = document.createElement("a");
  //     document.body.appendChild(a);
  //     a.href = downloadURL;
  //     a.download = fileName
  //     a.click(); */
  //   window.open(downloadURL);
  // }

  openImageFile(file) {
    let type = 'image/' + file.FileType;
    var arrBuffer = this.base64ToArrayBuffer(file.FileContent);
    var blob = new Blob([arrBuffer]);
    const downloadURL = URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = downloadURL;
    a.download = file.FileName;
    a.click();
    //window.open(downloadURL);
  }


  getPdfAttachmentDetail(packageID, fileID, fileName) {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId +
      '/ExternalId/' + this.selectedExternalID + '/PackageId/' + packageID + '/AttachmentId/' + fileID + '?code=' + environment.apiCode;

    this.http.get<any>(searchUrl, httpOptions).subscribe(data => {

      var arrBuffer = this.base64ToArrayBuffer(data);
      var blob = new Blob([arrBuffer], { type: 'application/pdf' });
      const downloadURL = URL.createObjectURL(blob);
      /*   var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = downloadURL;
        a.download = fileName
        a.click(); */
      window.open(downloadURL);
    },
      err => {
        console.log(err)
      });
  }


  getImageAttachmentDetail(packageID, fileID, fileExtension, fileName) {
    let type = 'image/' + fileExtension;
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId +
      '/ExternalId/' + this.selectedExternalID + '/PackageId/' + packageID + '/AttachmentId/' + fileID + '?code=' + environment.apiCode;

    this.http.get<any>(searchUrl, httpOptions).subscribe(data => {

      var arrBuffer = this.base64ToArrayBuffer(data);
      var blob = new Blob([arrBuffer], { type: type });
      const downloadURL = URL.createObjectURL(blob);
      /*  var a = document.createElement("a");
       document.body.appendChild(a);
       a.href = downloadURL;
       a.download = fileName
       a.click(); */
      window.open(downloadURL);
    },
      err => {
        console.log(err)
      });
  }

  getCsvAttachmentDetail(packageID, fileID, fileName) {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId +
      '/ExternalId/' + this.selectedExternalID + '/PackageId/' + packageID + '/AttachmentId/' + fileID + '?code=' + environment.apiCode;

    this.http.get<any>(searchUrl, httpOptions).subscribe(data => {

      var arrBuffer = this.base64ToArrayBuffer(data);
      var blob = new Blob([arrBuffer], { type: 'text/csv;charset=utf-8' });
      const downloadURL = URL.createObjectURL(blob);
      /*   var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = downloadURL;
        a.download = fileName
        a.click(); */
      window.open(downloadURL);
    },
      err => {
        console.log(err)
      });
  }

  getExcelAttachmentDetail(packageID, fileID, fileName) {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId +
      '/ExternalId/' + this.selectedExternalID + '/PackageId/' + packageID + '/AttachmentId/' + fileID + '?code=' + environment.apiCode;

    this.http.get<any>(searchUrl, httpOptions).subscribe(data => {

      var arrBuffer = this.base64ToArrayBuffer(data);
      var blob = new Blob([arrBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," });
      const downloadURL = URL.createObjectURL(blob);
      window.open(downloadURL);
    },
      err => {
        console.log(err)
      });
  }



  getDocAttachmentDetail(packageID, fileID, fileName) {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-email': email,
        'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId +
      '/ExternalId/' + this.selectedExternalID + '/PackageId/' + packageID + '/AttachmentId/' + fileID + '?code=' + environment.apiCode;

    this.http.get<any>(searchUrl, httpOptions).subscribe(data => {

      var arrBuffer = this.base64ToArrayBuffer(data);
      var blob = new Blob([arrBuffer], { type: 'text/plain;charset=utf-8' });
      const downloadURL = URL.createObjectURL(blob);
      window.open(downloadURL);
    },
      err => {
        console.log(err)
      });
  }


  base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  getWorkorderPrograms() {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + '/programs/programsponsorid/' + this.utilityService.currentUtility.ProgramsponsorId + '?code=' + environment.apiCode + '&org_cd=' + this.userService.orgCd;
    return this.http.get<Workorder[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [])
      );
  }

  getWorkorderStatus() {
    const searchUrl = this.woSearchWsUrl1 + this.utilityService.currentUtility.OfficeId
      + '/workorderstatus/programsponsorid/' + this.utilityService.currentUtility.ProgramsponsorId + '?code=' + environment.apiCode;
    return this.http.get<Workorder[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [])
      );
  }

  getWorkorderData(workorderID): Observable<any> {
    const searchUrl = environment.apiUrl + 'workorder/' + this.utilityService.currentUtility.OfficeId
      + '/statushistory/WorkorderID/' + workorderID + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [err])
      );
  }

  getWorkorderExceptions(workorderID): Observable<any> {
    const searchUrl = environment.apiUrl + 'workorder/' + this.utilityService.currentUtility.OfficeId
      + '/exceptions/' + workorderID + '/role/ADMIN' + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [err])
      );
  }

  addInspectionWorkorder(workorderID): Observable<any> {
    const inspWoUrl = environment.apiUrl + 'qa/' + this.utilityService.currentUtility.OfficeId
      + '/addinspection/' + workorderID + '/user/' + this.utilityService.currentUtility.BBCSUserId
      + '?code=' + environment.apiCode;

    return this.http.get<any[]>(inspWoUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [err])
      );
  }

  getActionsList() {
    const searchUrl = this.drActionUrl + 'draction/getdraction/' + this.utilityService.currentUtility.OfficeId + '?code=' + environment.apiCode;
    return this.http.get<any>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [])
      );
  }

  getDevices(personCompanyId) {
    const searchUrl = this.drActionUrl + 'draction/getdevicesdata/' + personCompanyId + '?code=' + environment.apiCode;
    return this.http.get<any>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [])
      );
  }

  getTransactions(personCompanyId) {
    const searchUrl = this.drActionUrl + 'draction/gettransactionsdata/' + personCompanyId + '?code=' + environment.apiCode;
    return this.http.get<any>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [])
      );
  }

  submitDrAction(personcompanyid, bodyContent): Observable<any[]> {
    const searchUrl = this.drActionUrl + 'draction/submitAction/' + this.utilityService.currentUtility.OfficeId
      + '/customer/' + personcompanyid + '?code=' + environment.apiCode;

    const body = bodyContent;

    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );

  }

  //-----------------Measures---------------
  deleteMeasure(workorderID, lineItem, subGroup, subGroup1): Observable<any> {
    const searchUrl =
      environment.apiUrl +
      "workorder/" +
      this.utilityService.currentUtility.OfficeId +
      "/measure/" +
      workorderID +
      "/deleteappliance/" +
      lineItem +
      "/user/" +
      this.utilityService.currentUtility.LoggedInUserRole +
      "?SubGroup=" +
      subGroup +
      "&SubGroup1=" +
      subGroup1 +
      "&code=" +
      environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  addMeasures(
    workorderID,
    SectionId = "addMeasure",
    subGroup,
    lineitemid = "ALL",
    lineitemmasterid = "ALL",
    subGroup1 = "ALL"
  ): Observable<any[]> {
    let BBCSUserId = this.getBBCSUserId();
    const searchUrl =
      environment.apiUrl +
      "workorder/" +
      this.utilityService.currentUtility.OfficeId +
      "/measure/" +
      workorderID +
      "/addappliance/" +
      SectionId +
      "/user/" +
      BBCSUserId +
      "/" +
      this.utilityService.currentUtility.LoggedInUserRole +
      "?SubGroup=" +
      subGroup +
      "&SubGroup1=" +
      subGroup1 +
      "&lineitemmasterid=" +
      lineitemmasterid +
      "&lineitemid=" +
      lineitemid +
      "&code=" +
      environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }
  //------------------Measures----------------------

  saveMeasureSalesImport(contentBody, workOrderId, subGroup, subGroup1) {
    const searchUrl =
      environment.apiUrl +
      "workorder/" +
      this.utilityService.currentUtility.OfficeId +
      "/savemeasure/" +
      workOrderId +
      "/user/" +
      this.userService.dbBBCSId +
      "/ADMIN?" +
      "SubGroup1=" +
      subGroup1 +
      "&org_cd=ALL" +
      "&SubGroup=" +
      subGroup + "&code=" +
      environment.apiCode;
    const body = contentBody;

    return this.http.post<any>(searchUrl, body, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  getBBCSUserId(): string {
    return this.userService.dbBBCSId === '' ? this.utilityService.currentUtility.BBCSUserId : this.userService.dbBBCSId;
  }
}
