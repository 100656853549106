<mat-card>
    <mat-card-header>
      <mat-card-title style="font-size: 16px">
        Washington Gas User Data
      </mat-card-title>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="ExportTOExcel()" [disabled]="count == 0">
          Export As Excel
        </button>
    </mat-card-actions>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br />
    <mat-card-content>
      <div class="mat-elevation-z8" #TABLE>
        <table mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="GasAccountNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</th>
            <td mat-cell *matCellDef="let report">
              {{ report.GasAccountNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="GasMeterID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Meter ID</th>
            <td mat-cell *matCellDef="let report">
              {{ report.GasMeterID }}
            </td>
          </ng-container>
          <ng-container matColumnDef="FirstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
            <td mat-cell *matCellDef="let report">
              {{ report.FirstName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell *matCellDef="let report">
              {{ report.LastName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="HouseNumberSupplement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>House Number</th>
            <td mat-cell *matCellDef="let report">
              {{ report.HouseNumberSupplement }}
            </td>
          </ng-container>
          <ng-container matColumnDef="PEAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
            <td mat-cell *matCellDef="let report">
              {{ report.PEAddress }}
            </td>
          </ng-container>
          <ng-container matColumnDef="City">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
            <td mat-cell *matCellDef="let report">
              {{ report.City }}
            </td>
          </ng-container>
          <ng-container matColumnDef="PostalCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Postal Code</th>
            <td mat-cell *matCellDef="let report">
              {{ report.PostalCode }}
            </td>
          </ng-container>
          <ng-container matColumnDef="CountyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>County</th>
            <td mat-cell *matCellDef="let report">
              {{ report.CountyName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
            <td mat-cell *matCellDef="let report">
              {{ report.Region }}
            </td>
          </ng-container>
          <ng-container matColumnDef="RateCategory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rate Category</th>
            <td mat-cell *matCellDef="let report">
              {{ report.RateCategory }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <br /><br />
        <mat-paginator [pageSizeOptions]="[10,20,30]" showFirstLastButtons></mat-paginator>
      </div>
      <ng-container *ngIf="count == 0">
        <span style="text-align: center !important; font-size: larger">No records found.</span>
      </ng-container>
    </mat-card-content>
  </mat-card>