import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkorderService } from 'src/app/services/workorder.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-canceldeactivate-dialog',
  templateUrl: './canceldeactivate-dialog.component.html',
  styleUrls: ['./canceldeactivate-dialog.component.scss']
})
export class CanceldeactivateDialogComponent implements OnInit {

  reasons: any = [];
  dialogTitle: any;
  workorderId: any;
  user: any;
  alertText:any;
  loadReasons:any = true;
  compassEnabled:any;
  compassJobId:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog, private workorderService: WorkorderService,
    private utilityService: UtilityService, private spinner: NgxSpinnerService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.loadReasons = true;
    let status = this.data.dataKey.StatusCode;
    this.dialogTitle = this.data.dataKey.DialogTitle;
    this.workorderService.getCancelDeactivateReasons(status).subscribe(res => {
    this.reasons = res;
    this.loadReasons = false;
    this.compassEnabled=localStorage.getItem('compassEnabled');
    this.compassJobId=localStorage.getItem('compassJobId');
    })
  }

  cancelDeactWorkorder(reason) {

    if (this.userService.dbBBCSId == '') {
      if (this.utilityService.currentProgramSponsor.SourceId === 'BBCS') {
        this.user = this.utilityService.currentUtility.BBCSUserId === null ? '' : this.utilityService.currentUtility.BBCSUserId;
      }
      else {
        this.user = localStorage.getItem('loggedInUserName') === null ? '' : localStorage.getItem('loggedInUserName');
      }
    } else {
        this.user = this.userService.dbBBCSId;
    }

    let workorderObj = {
      workorderID: this.data.dataKey.WorkorderID,
      User: this.user,
      Status: this.data.dataKey.StatusCode,
      Reason: reason.ReasonCode
    }

    this.data.dataKey.StatusCode == 920 ?
      this.alertText = "Deactivated Successfully"
      : this.data.dataKey.StatusCode == 250 ?
        this.alertText = "Rejected Successfully"
        : this.alertText = "Cancelled Successfully";

    Swal.fire({
      title: 'Save Changes?',
      text: "Are you sure you want to cancel/deactivate/reject workorder?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save Changes'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.data.dataKey.StatusCode != 250 ? this.doCancelDeact(workorderObj) : this.returnDeToWorkorder();
      }
    });

  }
  doCancelDeact(workorderObj) {
    this.workorderService.cancelDeactivateWorkorder(workorderObj).subscribe(res => {
      this.spinner.hide();
      //To implement compass integration for Cancel/Deactive workorder
      if (res.Action == "" && this.compassEnabled == "1") {

        this.workorderService.getCompassCancelDeactivateWorkorder(this.data.dataKey.WorkorderID, this.user, this.compassJobId).subscribe(res1 => {

          if (res1.Action) {

            Swal.fire({
              text: 'Cancel/Deactivation is not successful. Reason: ' + res1.Message,
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialog.closeAll();
            })

          }
          else {
            Swal.fire({
              text: this.alertText,
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialog.closeAll();
            })
          }

        }
        );
      }
      //compass Cancel workorder   
      if (res.Action == "" && this.compassEnabled == "0")
      { 
            Swal.fire({
              text: this.alertText,
              icon: 'success',
              confirmButtonText: 'OK',
          }).then(() => {
              this.dialog.closeAll();
          })

      }

      if (res.Action == "" && this.compassEnabled == "undefined")
      { 
            Swal.fire({
              text: this.alertText,
              icon: 'success',
              confirmButtonText: 'OK',
          }).then(() => {
              this.dialog.closeAll();
          })

      }
        
    
    if (res.Action != "" && this.compassEnabled == "0") {

    
      Swal.fire({
        text: res.Message,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then((result) => {

        if (result.value) {
          this.dialog.closeAll();
        }
      });
    }
   
  })
  }

  returnDeToWorkorder() {
    this.workorderService
      .returnToDEWorkorderService(this.data.dataKey.WorkorderID)
      .subscribe((res: any) => {
        if (res.Action == 0) {
          this.spinner.hide();
          Swal.fire({
            text: "Workorder returned to data entry",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.dialog.closeAll();
            }
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.dialog.closeAll();
            }
          });
        }
      });
  }

}


