<div style="float: right; top: -5px; right: -5px">
  <button mat-icon-button (click)="closedialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h5 mat-dialog-title>{{ popupLabel }}</h5>
<mat-divider></mat-divider>
<div mat-dialog-content style="width: 100vh">
  <br />
  <mat-card-content>
    <fieldset>
      <legend>Store Details</legend>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>Company Name</mat-label>
        <input matInput disabled="true" [value]="distributorService?.selectedCompany" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>Store Number</mat-label>
        <input matInput required [formControl]="stNo" />
        <mat-error *ngIf="stNo.invalid">Store number is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>City</mat-label>
        <input matInput required [formControl]="stCity" />
        <mat-error *ngIf="stCity.invalid">Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>State</mat-label>
        <input matInput required [formControl]="stState" maxlength="2" />
        <mat-error *ngIf="stState.invalid">A 2 letter state abbreviation is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-field-width50" *ngIf="
          this.distributorService.indDetail.StoresDetails[0]?.Individuals
            ?.Districts?.length == 0
        ">
        <mat-label>District</mat-label>
        <input matInput required [formControl]="stDistrict" />
        <mat-error *ngIf="stDistrict.invalid">Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-field-width50" *ngIf="
          this.distributorService.indDetail.StoresDetails[data.index ?? 0]
            ?.Individuals?.Districts?.length > 0
        ">
        <mat-label>District</mat-label>
        <mat-select [(ngModel)]="
            distributorService?.indDetail?.StoresDetails[data.index ?? 0]
              ?.Individuals.SelectedDistrictId
          " (selectionChange)="changedDistrictID($event.value)">
          <mat-option *ngFor="
              let org of distributorService?.indDetail?.StoresDetails[
                data.index ?? 0
              ]?.Individuals?.Districts
            " [value]="org.Id">
            {{ org.Value }}
          </mat-option>
        </mat-select>    
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>Street Name</mat-label>
        <input matInput [formControl]="stSName" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-field-width50">
        <mat-label>Street Number</mat-label>
        <input matInput  [formControl]="stSNum" />
      </mat-form-field>
    </fieldset>

    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="saveStore()" [disabled]="badData()">
        SAVE
      </button>
      <button mat-raised-button color="primary" (click)="reset()">
        CANCEL
      </button>
    </mat-card-actions>
  </mat-card-content>
</div>