import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UtilityService } from "./utility.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class DistributorService {
  indDetail;

  editMode = true;
  indName: any;

  orgList;
  indvList = [];
  selectedUtility;

  selectedCompany;
  selectedCompanyAbbr;
  selectedDistributorCode;
  selectedCompanyType;

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
    private userService : UserService
  ) { }

  private getHeaders() {
    const email = localStorage.getItem("loggedInUserEmail");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-email": email,
        "x-programsponsorid":
          this.utilityService.currentUtility.ProgramsponsorId,
      }),
    };
    return httpOptions;
  }

  public getOrganizationList(): Observable<any[]> {
    const userUtilityUrl =
      environment.apiUrl +
      "distributormaintenance/getutility/" +
      this.utilityService.currentUtility.OfficeId +
      "?code=" +
      environment.apiCode;
    return this.http.get<any[]>(userUtilityUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  public search(orgId: string, indName: any): Observable<any[]> {
    if (indName === undefined) {
      indName = "";
    }
    if (orgId === undefined) {
      orgId = "";
    }
    const searchUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributor/search?distributorname=" +
      indName +
      "&utility=" +
      orgId +
      "&code=" +
      environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  public newDetail(orgId: string, distributorCode: string) {
    const searchUrl =
      environment.apiUrl +
      "distributormaintenance/distributordetail/" +
      this.utilityService.currentUtility.OfficeId +
      "?distributorid=" +
      distributorCode +
      "&companyid=" +
      orgId +
      "&code=" +
      environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  saveDistributor(distributor) {
    let BBCSUserId = this.userService.dbBBCSId === '' ? this.utilityService.currentUtility.BBCSUserId : this.userService.dbBBCSId;
    const saveUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributor/save/userid/" +
      BBCSUserId +
      "?code=" +
      environment.apiCode;

    return this.http.post<any[]>(saveUrl, distributor, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  saveCompany(company) {
    const saveUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributor/company/save?code=" +
      environment.apiCode;
    return this.http.post<any[]>(saveUrl, company, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  saveStore(store, distributorId) {
    const saveUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributor/store/save/distributorid/" +
      distributorId +
      "?code=" +
      environment.apiCode;

    return this.http.post<any[]>(saveUrl, store, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  importStoreSKU(contentBody, isStore) {
    const searchUrl = isStore
      ? environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributorstorebulk/save/distributorid/" +
      this.selectedDistributorCode + "?code=" +
      environment.apiCode
      : environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributorstorebulksku/save/distributorid/" +
      this.selectedDistributorCode +
      "?code=" +
      environment.apiCode;

    const body = contentBody;

    return this.http.post<any>(searchUrl, body, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  importEquipmentUser(contentBody) {
    const searchUrl =
      environment.apiUrl + "bbcs/import/" + this.utilityService.currentUtility.OfficeId + "/user/" + this.userService.dbBBCSId +
      "?code=" + environment.apiCode;

    const body = contentBody;

    return this.http.post<any>(searchUrl, body, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  getWashingtonGasUsers(){
    const searchUrl =
      environment.apiUrl + "wg/get/" + this.utilityService.currentUtility.OfficeId + "?code=" + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  getSKUList() {
    const searchUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/SKUslist?code=" +
      environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  getStrorelist(distributorId) {
    const searchUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/getstorelist/" +
      distributorId +
      "?code=" +
      environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  getStoresOnCompany() {
    const searchUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributor/get/store/distributorid/" +
      this.selectedDistributorCode +
      "/companyname/" +
      this.selectedCompany +
      "?code=" +
      environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  getSKUOnDistributor() {
    const searchUrl =
      environment.apiUrl +
      "distributormaintenance/" +
      this.utilityService.currentUtility.OfficeId +
      "/distributor/get/Skus/distributorid/" +
      this.selectedDistributorCode +
      "?code=" +
      environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }

  getReport(reportName) {
    const searchUrl =
      environment.apiUrl + "bbcs/export/" + this.utilityService.currentUtility.OfficeId + "/user/" + this.userService.dbBBCSId + "?code=" + environment.apiCode + "&exportType=" + reportName;
    return this.http.get<any[]>(searchUrl, this.getHeaders()).pipe(
      map((response) => response),
      catchError((err) => this.utilityService.handleError(err, this, []))
    );
  }
}
