import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkorderService } from 'src/app/services/workorder.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onholdreason-dialog',
  templateUrl: './onholdreason-dialog.component.html',
  styleUrls: ['./onholdreason-dialog.component.scss']
})
export class OnholdreasonDialogComponent implements OnInit {

  reasons: any = [];
  dialogTitle: any;
  workorderId: any;
  user: any;
  alertText:any;
  loadReasons:any = true;
  compassJobId:any;
  compassEnabled:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog, private workorderService: WorkorderService,
    private utilityService: UtilityService, private spinner: NgxSpinnerService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.loadReasons = true;
    let status = this.data.dataKey.StatusCode;
    this.dialogTitle = this.data.dataKey.DialogTitle;
    this.user = this.userService.dbBBCSId === '' ? this.utilityService.currentUtility.BBCSUserId : this.userService.dbBBCSId;
    this.compassEnabled=localStorage.getItem('compassEnabled');
    this.compassJobId=localStorage.getItem('compassJobId');
    console.log('compassenabled:',this.compassEnabled);
    this.workorderService.getOnHoldReasons(status).subscribe(res => {
      this.reasons = res;
      this.loadReasons = false;
    })
   
  }

  onHold(reason) {
    if (this.userService.dbBBCSId == '') {
      if (this.utilityService.currentProgramSponsor.SourceId === 'BBCS') {
        this.user = this.utilityService.currentUtility.BBCSUserId === null ? '' : this.utilityService.currentUtility.BBCSUserId;
      }
      else {
        this.user = localStorage.getItem('loggedInUserName') === null ? '' : localStorage.getItem('loggedInUserName');
      }
    } else {
      this.user = this.userService.dbBBCSId;
    }

    let workorderObj = {
      workorderID: this.data.dataKey.WorkorderID,
      User: this.user,
      Status: this.data.dataKey.StatusCode,
      Reason: reason.ReasonCode
    }


    Swal.fire({
      title: 'Save Changes?',
      text: "Are you sure you want to put this workorder on hold?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save Changes'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.onProcessHold(workorderObj);
      }
    });

  }

  onProcessHold(workorderObj) {
    this.workorderService.pendHold(workorderObj.workorderID,workorderObj.Status,workorderObj.Reason,workorderObj.User).subscribe(res => 
      {
        
//compassIntegration-start
      if(!res.action && this.compassEnabled == "1")
      {
        this.workorderService.getCompassOnHold(workorderObj.workorderID,this.user,this.compassJobId).subscribe(res1 => 
          {
            if(res1.Action)
            {
              this.spinner.hide();
              Swal.fire({
                text: ' Onhold not successful. Reason: '+ res1.Message,
                icon: 'error',
                confirmButtonText: 'OK',
              }).then((result) => {
                if (result.value) {
                  this.dialog.closeAll();
                }
              }); 
            }
            else
            {
              this.spinner.hide();
              Swal.fire({
                text: "Workorder onhold is successful",
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(() => {
              this.dialog.closeAll();
             })
            }

          });

        }
         if (!res.action && this.compassEnabled == "0")
          { 
            this.spinner.hide();
            Swal.fire({
              text: "Workorder onhold is successful",
              icon: 'success',
              confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.closeAll();
          })

          }

          if (!res.action && this.compassEnabled == "undefined")
          { 
            this.spinner.hide();
            Swal.fire({
              text: "Workorder onhold is successful",
              icon: 'success',
              confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.closeAll();
          })

          }
      
      if (res.Action && this.compassEnabled == "0")
      {
        this.spinner.hide();
        Swal.fire({
          text: res.message,
          icon: 'error',
          confirmButtonText: 'OK',
      }).then(() => {
          this.dialog.closeAll();
      })
      }
   
    })
  }
//compassIntegration -end

}
