import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorService } from 'src/app/services/distributor.service';
import * as XLSX from "xlsx";

@Component({
  selector: 'app-washington-gas-users',
  templateUrl: './washington-gas-users.component.html',
  styleUrls: ['./washington-gas-users.component.scss']
})

export class WashingtonGasUsersComponent implements OnInit {

  @ViewChild("TABLE") table: ElementRef;
  dataSource = new MatTableDataSource<any>();
  alldata: any = [];
  displayedColumns: string[] = [
    "GasAccountNumber",
    "GasMeterID",
    "FirstName",
    "LastName",
    "HouseNumberSupplement",
    "PEAddress",
    "City",
    "PostalCode",
    "CountyName",
    "Region",
    "RateCategory"
  ];
  count: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor(
    public distributorService: DistributorService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.distributorService.getWashingtonGasUsers().subscribe((data) => {
      this.alldata = data;
      this.dataSource.data = this.alldata;
      this.count = data.length;
      this.spinner.hide();
    });
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.alldata);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, "WashingtonGasUsersReport.xlsx");
  }

}
