<div style="display: flex;">
    <mat-card class="user-card" style="height: 350px;overflow: scroll">
        <mat-form-field class="tp-full-width" appearance="outline">
            <mat-label>Chart Type</mat-label>
            <mat-select (selectionChange)="changeSelectedType($event)" [(ngModel)]="selectedChartType">
                <mat-option *ngFor="let type of chartType" [value]="type" style="font-size: 11px;">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <canvas baseChart [datasets]="chartData" [labels]="displayedLabels" [options]="barChartOptions"
                [legend]="true" [type]="selectedChartType" (chartClick)="chartClicked($event, false)"></canvas>
        </div>
    </mat-card>

    <mat-card class="user-card" style="height: 350px;overflow: scroll" *ngIf="userService.resourceRole?.toLowerCase() == 'admin'">
        <mat-form-field class="tp-full-width" appearance="outline" style="padding-right: 20px;">
            <mat-label>Workorder Type</mat-label>
            <mat-select (selectionChange)="changeWorkorderType($event)" [(ngModel)]="selectedWorkorderType">
                <mat-option *ngFor="let workorder of dataMatrix" [value]="workorder.WorkorderType"
                    style="font-size: 11px;">
                    {{workorder.WorkorderType}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="tp-full-width" appearance="outline">
            <mat-label>Chart Type</mat-label>
            <mat-select (selectionChange)="changeWoSelectedType($event)" [(ngModel)]="barChartType">
                <mat-option *ngFor="let type of chartType" [value]="type" style="font-size: 11px;">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <canvas baseChart [datasets]="barChartData" [labels]="displayedLabels" [options]="barChartOptions"
                [legend]="true" [type]="barChartType" (chartClick)="chartClicked($event, true)"></canvas>
        </div>
    </mat-card>

    <mat-card class="user-card" style="height: 350px;overflow: scroll"
        *ngIf="userService.resourceRole?.toLowerCase() != 'admin'">
        <canvas baseChart [datasets]="barChartData" [labels]="displayedLabels" [options]="barChartOptions"
            [type]="barChartType" (chartClick)="matrixChartClicked($event)">
        </canvas>
    </mat-card>
</div>

<mat-card class="user-card">
    <div style="display:flex">
        <div>
            <mat-card-title style="font-size: 16px;">
                <span>Workorders</span>
            </mat-card-title>
        </div>
        <div style="flex-grow: 1"></div>
        <button mat-flat-button color="primary" type="submit" (click)="refrehPage()">Reset Filter</button>
    </div>
    <br />
    <mat-divider></mat-divider>
    <mat-card-content>
        <br />

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
            <ng-container matColumnDef="WorkorderID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Workorder ID
                    <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <mat-menu #menu>
                        <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                            Workorder ID
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <mat-form-field>
                                <input matInput class="form-field" [formControl]="workorderIDFilter"
                                    placeholder="Value">
                            </mat-form-field>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <button mat-raised-button (click)="clearColumn('workorderIDFilter')"
                                color="primary">Clear</button>
                        </div>
                    </mat-menu>
                </th>
                <td mat-cell *matCellDef="let row"> {{row.WorkorderID}} </td>
            </ng-container>

            <ng-container matColumnDef="WorkorderType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Workorder Type
                    <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <mat-menu #menu>
                        <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                            Workorder Type
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <mat-form-field>
                                <input matInput class="form-field" [formControl]="workorderTypeFilter"
                                    placeholder="Value">
                            </mat-form-field>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <button mat-raised-button (click)="clearColumn('workorderTypeFilter')"
                                color="primary">Clear</button>
                        </div>
                    </mat-menu>
                </th>
                <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.WorkorderType}}">
                    {{workorder.WorkorderType}} </td>
            </ng-container>

            <ng-container matColumnDef="AccountHolder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Holder
                    <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <mat-menu #menu>
                        <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                            Account Holder
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <mat-form-field>
                                <input matInput class="form-field" [formControl]="accountHolderFilter"
                                    placeholder="Value">
                            </mat-form-field>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <button mat-raised-button (click)="clearColumn('accountHolderFilter')"
                                color="primary">Clear</button>
                        </div>
                    </mat-menu>
                </th>
                <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.AccountHolder}}"> {{
                    workorder.AccountHolder }} </td>
            </ng-container>

            <ng-container matColumnDef="Distributor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span>Distributor Name</span>
                    <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <mat-menu #menu>
                        <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                            <span>Distributor Name</span>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <mat-form-field>
                                <input matInput class="form-field" [formControl]="distributorFilter"
                                    placeholder="Value">
                            </mat-form-field>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <button mat-raised-button (click)="clearColumn('distributorFilter')"
                                color="primary">Clear</button>
                        </div>
                    </mat-menu>

                </th>
                <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.Distributor}}"> {{
                    workorder.Distributor }} </td>
            </ng-container>

            <ng-container matColumnDef="Contractor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span>Contractor Name</span>
                    <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <mat-menu #menu>
                        <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                            <span>Contractor Name</span>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <mat-form-field>
                                <input matInput class="form-field" [formControl]="contractorFilter" placeholder="Value">
                            </mat-form-field>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <button mat-raised-button (click)="clearColumn('contractorFilter')"
                                color="primary">Clear</button>
                        </div>
                    </mat-menu>

                </th>
                <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.Contractor}}"> {{
                    workorder.Contractor }} </td>
            </ng-container>

            <ng-container matColumnDef="WorkorderStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Workorder Status
                    <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <mat-menu #menu>
                        <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                            Workorder Status
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <mat-form-field>
                                <input matInput class="form-field" [formControl]="workorderStatusFilter"
                                    placeholder="Value">
                            </mat-form-field>
                        </div>
                        <div mat-menu-item mat-filter-item [disableRipple]="true">
                            <button mat-raised-button (click)="clearColumn('workorderStatusFilter')"
                                color="primary">Clear</button>
                        </div>
                    </mat-menu>
                </th>
                <td mat-cell *matCellDef="let workorder" matTooltip="{{workorder.WorkorderStatus}}"> {{
                    workorder.WorkorderStatus }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Updated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated Date</th>
                <td mat-cell *matCellDef="let workorder"> {{workorder.Updated | date:'shortDate' }} </td>
            </ng-container>

            <ng-container matColumnDef="Details">
                <th mat-header-cell *matHeaderCellDef style="width: 6% !important">Details</th>
                <td mat-cell *matCellDef="let workorder">
                    <button mat-button (click)="handleEnrollmentWorkorderClick(workorder, workorder.EnrollmentId)">
                        <mat-icon style="visibility: visible;color:#3CB371;">label_important</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
</mat-card>