import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { HttpClientModule } from "@angular/common/http";
import { Router } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { OverlayContainer } from "@angular/cdk/overlay";
import { MatNativeDateModule } from "@angular/material/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { CustomerSearchComponent } from "./customer-search/customer-search.component";
import { OrganizationMaintenanceComponent } from "./organization-maintenance/organization-maintenance.component";
import {
  MsAdalAngular6Module,
  AuthenticationGuard,
} from "microsoft-adal-angular6";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { environment } from "src/environments/environment";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatDividerModule } from "@angular/material/divider";
import { DetailedoutputComponent } from "./detailedoutput/detailedoutput.component";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { ConfirmDialogComponent } from "./util/confirm-dialog/confirm-dialog.component";
import { WaitDialogComponent } from "./util/wait-dialog/wait-dialog.component";
import { AddinteractionDialogComponent } from "./util/addinteraction-dialog/addinteraction-dialog.component";
import { WorkorderHistoryDialogComponent } from "./util/workorder-history-dialog/workorder-history-dialog.component";
import { WorkorderStatusDialogComponent } from "./util/workorder-status-dialog/workorder-status-dialog.component";
import { StatusDialogComponent } from "./util/status-dialog/status-dialog.component";
// import Swal from "sweetalert2";
import { MatStepperModule } from "@angular/material/stepper";
import { WorkorderDetailComponent } from "./workorder-detail/workorder-detail.component";
import { OfficeComponent } from "./user/office/office.component";
import { ProgramSponsorComponent } from "./user/program-sponsor/program-sponsor.component";
import { RoleComponent } from "./user/role/role.component";
import { ModuleComponent } from "./user/module/module.component";
import { UserComponent } from "./user/user/user.component";
import { OfficeDetailComponent } from "./user/office-detail/office-detail.component";
import { ProgramSponsorDetailComponent } from "./user/program-sponsor-detail/program-sponsor-detail.component";
import { RoleDetailComponent } from "./user/role-detail/role-detail.component";
import { UserDetailComponent } from "./user/user-detail/user-detail.component";
import { ModuleDetailComponent } from "./user/module-detail/module-detail.component";
import { RoleCreateComponent } from "./user/role-create/role-create.component";
import { ModuleCreateComponent } from "./user/module-create/module-create.component";
import { UserCreateComponent } from "./user/user-create/user-create.component";
import { OfficeCreateComponent } from "./user/office-create/office-create.component";
import { ProgramSponsorCreateComponent } from "./user/program-sponsor-create/program-sponsor-create.component";
import { ReportComponent } from "./report/report.component";
import { TableauVisualizationComponent } from "./tableau-visualization/tableau-visualization.component";
import { EligibilityComponent } from "./enrollment/eligibility/eligibility.component";
import { EnrollComponent } from "./enrollment/enroll/enroll.component";
import { WorkorderGetSaveComponent } from "./workorder-getsave/workorder-getsave.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { InputlengthFilterDirective } from "./util/input-filter/inputlength-filter.directive";
import { IndividualMaintenanceComponent } from "./individual-maintenance/individual-maintenance.component";
import { IndividualDetailComponent } from "./individual-maintenance/individual-detail/individual-detail.component";
import { SchedulingComponent } from "./scheduling/scheduling.component";
import { SchedulerDialogComponent } from "./util/scheduler-dialog/scheduler-dialog.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { CanceldeactivateDialogComponent } from "./util/canceldeactivate-dialog/canceldeactivate-dialog.component";
import { AddWorkorderComponent } from "./util/add-workorder/add-workorder.component";
import { UploadDialogComponent } from "./util/upload-dialog/upload-dialog.component";
import { ViewdocsDialogComponent } from "./util/viewdocs-dialog/viewdocs-dialog.component";
import { WorkorderExceptionsComponent } from "./workorder-exceptions/workorder-exceptions.component";
import { AddCustomerDialogComponent } from "./util/add-customer-dialog/add-customer-dialog.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { DayViewSchedulerComponent } from "./day-view-scheduler/day-view-scheduler.component";
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { CancelscheduleDialogComponent } from "./util/cancelschedule-dialog/cancelschedule-dialog.component";
import { PhoneComponent } from "./individual-maintenance/dialogs/phone/phone.component";
import { AddressComponent } from "./individual-maintenance/dialogs/address/address.component";
import { GridComponent } from "./individual-maintenance/dialogs/grid/grid.component";
import { DailyHoursComponent } from "./individual-maintenance/dialogs/daily-hours/daily-hours.component";
import { TimeOffComponent } from "./individual-maintenance/dialogs/time-off/time-off.component";
import { AdditionalHoursComponent } from "./individual-maintenance/dialogs/additional-hours/additional-hours.component";
import { IndividualRoleComponent } from "./individual-maintenance/dialogs/individual-role/individual-role.component";
import { RescheduleDialogComponent } from "./util/reschedule-dialog/reschedule-dialog.component";
import { BucketSchedulingComponent } from "./individual-maintenance/dialogs/bucket-scheduling/bucket-scheduling.component";
import { LocationSearchComponent } from "./location-search_/location-search.component";
import { MatFileUploadComponent } from "./shared/mat-file-upload.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { DatePipe } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ReportConfigComponent } from "./user/report-configuration/report-config/report-config.component";
import { ReportConfigEditComponent } from "./user/report-config-edit/report-config-edit.component";
import { CdkDetailRowDirective } from "./detailedoutput/cdk-detail-row.directive";
import { GoogleMapsComponent } from "./google-maps/google-maps.component";
// import { ReportConfigComponent } from './user/report-configuration/report-config/report-config.component';
// import { ReportConfigEditComponent } from './user/report-config-edit/report-config-edit.component';
//import { AgmCoreModule } from "@agm/core";
import { BucketScheduleComponent } from './bucket-scheduling/bucket-scheduling.component';
import { TimeslotDialogComponent } from './util/timeslot-dialog/timeslot-dialog.component';
import { RouteOptimizationComponent } from './route-optimization/route-optimization.component';
import { InventoryMaintenaceComponent } from './inventory/inventory-maintenace/inventory-maintenace.component';
import { OnholdreasonDialogComponent } from './util/onholdreason-dialog/onholdreason-dialog.component';
import { NgInitDirective } from './services/ng-init.directive';
import { ProgramenrollmentComponent } from "./programenrollment/programenrollment.component";
import { EnrollQualifyComponent } from './enroll-qualify/enroll-qualify.component';
import { Location, LocationStrategy, PathLocationStrategy, APP_BASE_HREF } from "@angular/common";
import { EnrollVerifyComponent } from './enroll-verify/enroll-verify.component';
import { RouteOptimizationDetailComponent } from "./route-optimization-detail/route-optimization-detail.component";
import { EnrollmentportaladminComponent } from './user/enrollmentportaladmin/enrollmentportaladmin.component';
import { ClientReportsComponent } from './client-reports/client-reports.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { EnrollcustomerComponent } from './enrollcustomer/enrollcustomer.component';
import { DractionsComponent } from './dractions/dractions.component';
import { RetainformdataService } from "./retainformdata.service";
import { ChargersComponent } from './custom/chargers/chargers.component';
import { ChargerDialogComponent } from './custom/chargers/charger-dialog/charger-dialog.component';
import { EditaddressComponent } from './detailedoutput/dialogs/editaddress/editaddress.component';
import { EditCustomerNameComponent } from './detailedoutput/dialogs/edit-customer-name/edit-customer-name.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { HomeAuditMeasureComponent } from "./home-audit-measure/home-audit-measure.component";
import { MeasuresComponent } from './measures/measures.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DecimalnumberDirective } from "./directive/decimalnumber.directive";
import { NumericDirective } from "./directive/numeric.directive";
import { ComparisonScreenDialogComponent } from './util/comparison-screen-dialog/comparison-screen-dialog.component';
import * as Sentry from "@sentry/angular-ivy";

import { DistributorComponent } from "./distributor/distributor.component";
import { DistributorDetailComponent } from "./distributor/distributor-detail/distributor-detail.component";
import { StoresComponent } from "./distributor/dialogs/stores/stores.component";
import { UploadExcelComponent } from "./distributor/dialogs/upload-excel/upload-excel.component";
import { CompanyComponent } from "./distributor/dialogs/company/company.component";
import { ManufacturerDistributerReportComponent } from "./distributor/manufacturer-distributer-report/manufacturer-distributer-report.component";
import { EnergyStarReportComponent } from "./distributor/energy-star-report/energy-star-report.component";
import { DashobaordComponent } from "./dashobaord/dashobaord.component";
import { NgxMaskModule } from 'ngx-mask'
import { FilterItemDirective } from "./directive/filteritem.directive";
import { WorkorderAgingComponent } from './workorder-aging/workorder-aging.component';
import { ChartDashboardComponent } from './chart-dashboard/chart-dashboard.component';
import { NgChartsModule } from 'ng2-charts';
import { UsageDialogComponent } from './detailedoutput/dialogs/usage-dialog/usage-dialog.component';
import { WashingtonGasUsersComponent } from './distributor/washington-gas-users/washington-gas-users.component';

@NgModule({
  declarations: [
    AppComponent,
    CustomerSearchComponent,
    OrganizationMaintenanceComponent,
    DetailedoutputComponent,
    ConfirmDialogComponent,
    WaitDialogComponent,
    AddinteractionDialogComponent,
    WorkorderHistoryDialogComponent,
    WorkorderStatusDialogComponent,
    StatusDialogComponent,
    WorkorderDetailComponent,
    OfficeComponent,
    ProgramSponsorComponent,
    RoleComponent,
    ModuleComponent,
    UserComponent,
    OfficeDetailComponent,
    ProgramSponsorDetailComponent,
    RoleDetailComponent,
    UserDetailComponent,
    ModuleDetailComponent,
    RoleCreateComponent,
    ModuleCreateComponent,
    UserCreateComponent,
    OfficeCreateComponent,
    ProgramSponsorCreateComponent,
    SchedulingComponent,
    ReportComponent,
    TableauVisualizationComponent,
    EligibilityComponent,
    EnrollComponent,
    WorkorderGetSaveComponent,
    InputlengthFilterDirective,
    IndividualMaintenanceComponent,
    IndividualDetailComponent,
    SchedulerDialogComponent,
    CanceldeactivateDialogComponent,
    AddWorkorderComponent,
    UploadDialogComponent,
    ViewdocsDialogComponent,
    AddCustomerDialogComponent,
    DayViewSchedulerComponent,
    WorkorderExceptionsComponent,
    CancelscheduleDialogComponent,
    PhoneComponent,
    AddressComponent,
    GridComponent,
    DailyHoursComponent,
    TimeOffComponent,
    AdditionalHoursComponent,
    IndividualRoleComponent,
    RescheduleDialogComponent,
    BucketSchedulingComponent,
    LocationSearchComponent,
    MatFileUploadComponent,
    ReportConfigComponent,
    ReportConfigEditComponent,
    CdkDetailRowDirective,
    GoogleMapsComponent,
    BucketScheduleComponent,
    TimeslotDialogComponent,
    RouteOptimizationComponent,
    InventoryMaintenaceComponent,
    OnholdreasonDialogComponent,
    NgInitDirective,
    ProgramenrollmentComponent,
    EnrollQualifyComponent,
    EnrollVerifyComponent,
    RouteOptimizationDetailComponent,
    EnrollmentportaladminComponent,
    ClientReportsComponent,
    EnrollcustomerComponent,
    DractionsComponent,
    ChargersComponent,
    ChargerDialogComponent,
    EditaddressComponent,
    EditCustomerNameComponent,
    UploadDocumentsComponent,
    HomeAuditMeasureComponent,
    MeasuresComponent,
    DecimalnumberDirective,
    NumericDirective,
    ComparisonScreenDialogComponent,
    DistributorComponent,
    DistributorDetailComponent,
    StoresComponent,
    UploadExcelComponent,
    CompanyComponent,
    ManufacturerDistributerReportComponent,
    EnergyStarReportComponent,
    DashobaordComponent,
    FilterItemDirective,
    WorkorderAgingComponent,
    ChartDashboardComponent,
    UsageDialogComponent,
    WashingtonGasUsersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatGridListModule,
    MatCardModule,
    MatTabsModule,
    MatSidenavModule,
    MatMenuModule,
    MatInputModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDividerModule,
    MatListModule,
    MatPaginatorModule,
    MatStepperModule,
    MatDatepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatAutocompleteModule,
    Ng2SearchPipeModule,
    DragDropModule,
    NgChartsModule,
    NgxMaskModule.forRoot({
      showMaskTyped: false,
    }),
    NgxSpinnerModule.forRoot({ type: "timer" }),
    MsAdalAngular6Module.forRoot(environment.adalConfiguration),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ContextMenuModule,
    /* AgmCoreModule.forRoot({
       apiKey: "AIzaSyAIzaSyA8WVHdMT37ZKnrRSBLJOor0ucbs0LVMEw",
       libraries: ["geometry"],
     }),*/
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.sentryShowdialog,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' },
    AuthenticationGuard, DatePipe, RetainformdataService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    // so themes get applied to dialogs and menus
    const className = localStorage.getItem("themeName");
    overlayContainer.getContainerElement().classList.add(className);
  }
}
