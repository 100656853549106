import { NgModule } from '@angular/core';

import { Routes, RouterModule, Router } from '@angular/router';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { DetailedoutputComponent } from './detailedoutput/detailedoutput.component';
import { AppComponent } from './app.component';
import { WorkorderDetailComponent } from './workorder-detail/workorder-detail.component';
import { ModuleComponent } from './user/module/module.component';
import { OfficeComponent } from './user/office/office.component';
import { ProgramSponsorComponent } from './user/program-sponsor/program-sponsor.component';
import { RoleComponent } from './user/role/role.component';
import { UserComponent } from './user/user/user.component';
import { ModuleDetailComponent } from './user/module-detail/module-detail.component';
import { OfficeDetailComponent } from './user/office-detail/office-detail.component';
import { ProgramSponsorDetailComponent } from './user/program-sponsor-detail/program-sponsor-detail.component';
import { RoleDetailComponent } from './user/role-detail/role-detail.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { ReportComponent } from './report/report.component';
import { TableauVisualizationComponent } from './tableau-visualization/tableau-visualization.component';
import { EligibilityComponent } from './enrollment/eligibility/eligibility.component';
import { WorkorderGetSaveComponent } from './workorder-getsave/workorder-getsave.component';
import { IndividualMaintenanceComponent } from './individual-maintenance/individual-maintenance.component';
import { OrganizationMaintenanceComponent } from './organization-maintenance/organization-maintenance.component';
import { IndividualDetailComponent } from './individual-maintenance/individual-detail/individual-detail.component';
import {SchedulingComponent} from './scheduling/scheduling.component';
import { LocationSearchComponent } from './location-search_/location-search.component';
import { ReportConfigComponent } from './user/report-configuration/report-config/report-config.component';
import { BucketScheduleComponent } from './bucket-scheduling/bucket-scheduling.component';
import { RouteOptimizationComponent } from './route-optimization/route-optimization.component';
//import { ReportConfigComponent } from './user/report-configuration/report-config/report-config.component';
import { InventoryMaintenaceComponent } from './inventory/inventory-maintenace/inventory-maintenace.component';
import { EnrollQualifyComponent } from './enroll-qualify/enroll-qualify.component';
import { RouteOptimizationDetailComponent } from './route-optimization-detail/route-optimization-detail.component';
import { EnrollmentportaladminComponent } from './user/enrollmentportaladmin/enrollmentportaladmin.component';
import { ClientReportsComponent } from './client-reports/client-reports.component';
import { EnrollcustomerComponent } from './enrollcustomer/enrollcustomer.component';
import { DractionsComponent } from './dractions/dractions.component';
import { DistributorComponent } from "./distributor/distributor.component";
import { DistributorDetailComponent } from "./distributor/distributor-detail/distributor-detail.component";
import { ManufacturerDistributerReportComponent } from "./distributor/manufacturer-distributer-report/manufacturer-distributer-report.component";
import { EnergyStarReportComponent } from "./distributor/energy-star-report/energy-star-report.component";
import { DashobaordComponent } from "./dashobaord/dashobaord.component";
import { WorkorderAgingComponent } from "./workorder-aging/workorder-aging.component";
import { ChartDashboardComponent } from "./chart-dashboard/chart-dashboard.component";
import { WashingtonGasUsersComponent } from './distributor/washington-gas-users/washington-gas-users.component';


const routes: Routes = [
    { path: 'search', component: CustomerSearchComponent, /* canActivate: [AuthenticationGuard], */ runGuardsAndResolvers: 'always' },
    { path: 'detail', component: DetailedoutputComponent, /* canActivate: [AuthenticationGuard], */ runGuardsAndResolvers: 'always' },
    { path: 'workorderDetail', component: WorkorderDetailComponent, /* canActivate: [AuthenticationGuard], */ runGuardsAndResolvers: 'always' },
    { path: 'moduleAdmin', component: ModuleComponent },
    { path: 'officeAdmin', component: OfficeComponent },
    { path: 'programSponsorAdmin', component: ProgramSponsorComponent },
    { path: 'roleAdmin', component: RoleComponent },
    { path: 'userAdmin', component: UserComponent },
    { path: 'moduleDetail', component: ModuleDetailComponent },
    { path: 'officeDetail', component: OfficeDetailComponent },
    { path: 'programSponsorDetail', component: ProgramSponsorDetailComponent },
    { path: 'roleDetail', component: RoleDetailComponent },
    { path: 'userDetail', component: UserDetailComponent },
    { path: 'reportAdmin/:id', component: ReportComponent },
    {path:'enrollmentAdmin', component:EnrollmentportaladminComponent},
    { path: 'Reports', component: TableauVisualizationComponent },
    { path: 'eligibility', component: EligibilityComponent },
    { path: 'workorderGetSave/:group/:subgroup/:workorderID/:subgroup1', component: WorkorderGetSaveComponent},
/*     { path: 'workorderGetSave/:category/:subCategory/:WorkorderId', component: WorkorderGetSaveComponent},
 */    { path: 'individualmaintenance/:toggle', component: IndividualMaintenanceComponent },
    { path: 'organizationmaintenance', component: OrganizationMaintenanceComponent },
    { path: 'schedulemaintenance', component: SchedulingComponent },
    { path: 'individualDetail', component: IndividualDetailComponent},
    {path:'scheduling/:group/:subgroup/:id/:id2',component:SchedulingComponent},
    //{path:'locationsearch/:id/:id2', component: LocationSearchComponent},
    {path:'locationsearch', component: LocationSearchComponent},
    {path:'reportConfig', component: ReportConfigComponent},
    {path:'bucketSchedule/:workorderID', component: BucketScheduleComponent},
    {path:'routeOptimization',component:RouteOptimizationComponent},
    { path: 'enrollqualify', component:EnrollQualifyComponent},
    { path: '', redirectTo: '/search', pathMatch: 'full' },
	{ path: 'inventory', component:InventoryMaintenaceComponent},
	{path:'routeOptimizationDetail', component:RouteOptimizationDetailComponent},
    {path:'clientReports', component:ClientReportsComponent},
    { path: 'enrollcustomer', component: EnrollcustomerComponent },
    { path: 'dractions', component: DractionsComponent },
    { path: "distributormaintenance/:toggle", component: DistributorComponent },
    { path: "distributorDetail", component: DistributorDetailComponent },
    { path: "manufacturerDistributerReport", component: ManufacturerDistributerReportComponent },
    { path: "energyStarReport", component: EnergyStarReportComponent },
    { path: 'dashboard', component: DashobaordComponent, data: { title: 'Dashobaord' } },
    { path: 'workorderAging', component: WorkorderAgingComponent },
    { path: 'graphDashboard', component: ChartDashboardComponent, data: { title: 'Dashobaord' } },
    { path: "washingtonGasUsers", component: WashingtonGasUsersComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
