import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { OrganizationSearch } from '../models/organizationsearch'
import { OrganizationDetail, DocumentsTab, TYPE, CLASSIFICATION, OrganizationTab, AccreditationsTab } from '../models/organizationdetail'
import { OrganizationMaintainenceService } from '../services/organization-maintainence.service'
import { ExportUtilService } from '../services/export-util.service'
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import { MatSnackBar } from "@angular/material/snack-bar";
import { UtilityService } from './../services/utility.service';
import { WaitDialogComponent } from '../util/wait-dialog/wait-dialog.component';
import { DataTransferService } from '../services/data-transfer.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-organization-maintenance',
  templateUrl: './organization-maintenance.component.html',
  styleUrls: ['./organization-maintenance.component.scss']
})
export class OrganizationMaintenanceComponent implements OnInit, AfterViewInit {

  constructor(private formBuilder: UntypedFormBuilder, private organizationMaintainenceService: OrganizationMaintainenceService,
    private exportUtilService: ExportUtilService, 
    private snackBar: MatSnackBar, private dataTransfer:DataTransferService,
    private utilityService: UtilityService, private dialog: MatDialog,) { }

  //Accreditations Tab
  dataSourceAccreditations = new MatTableDataSource<AccreditationsTab>();
  displayedColumnsAccreditations: string[] = ['Classification', 'Number', 'Issued', 'Expires', 'State', 'Specialty','Edit','Delete'];
  addAccred: boolean = false;
  editAccred: boolean =false;

  addOrEditText: string;
  newAccreditation: any;
  oldAccreditation: any;

  formAccredGroup: UntypedFormGroup;

  addonOptions = [{ addonName: "name1", isChecked: false }, { addonName: "name2", isChecked: true }, { addonName: "name3", isChecked: false }, { addonName: 'name4', isChecked: true }]
  selectedOrgForm: UntypedFormGroup;
  @ViewChild('Tabs', { static: true }) organizationMaintenanceTabs: MatTabGroup;
  formGroup: UntypedFormGroup;
  formDetailedGroup: UntypedFormGroup;
  formCheckDetailedGroup: UntypedFormGroup;
  formDocGroup: UntypedFormGroup;
  organizationForm: any;
  organization: OrganizationSearch[] | any = [];
  newOrganization: OrganizationSearch;
  organizationDetails: any;
  displayedColumns: string[] = ['ORGANIZATION_DESC', 'ORGANIZATION_CD', 'CITY', 'PHONE_NUMBER', 'EXTERNALKEY'];
  dataSourceOrganization = new MatTableDataSource<OrganizationSearch>(); // OrganizationalSearch[];
  orgSelection = new SelectionModel<OrganizationSearch>(false, []);
  @ViewChild(MatSort) sort: MatSort;
  organizationSearching: boolean = false;
  displayedDocumentsColumns: string[] = ['columnEdit', 'TITLE', 'DESCRIPTION', 'LASTUPDATED', 'columnView', 'columnDelete'] //, 'ACTIVE'];
  dataSourceDocuments = new MatTableDataSource<DocumentsTab>();
  isOrgSelected: boolean = true;
  activeDocuments: boolean = false;
  docSelection = new SelectionModel<DocumentsTab>(true, []);
  typeOpt: TYPE[];
  classificationOpt: CLASSIFICATION[]
  contractors: string[] = [];
  selectedIndex: number = 0;
  organizationContractors: string[] = [];
  selectedOrg: string;
  orgType: boolean;
  addNewOrg: OrganizationTab
  addZip: string;
  docType: boolean = false;
  docActionName: string = "Upload New Document"
  addOrgDetails: any;
  contractorPayload: any;
  contractorStatus: boolean = false;
  extractedFileData: any;
  docSavetype: boolean = true;
  fileName: string;
  newOrgSel: boolean = false;
  select_all = false;
  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  formDetailedStatus: boolean = false;
  formDocStatus: boolean = false;
  hideSave: boolean = false;
  sysImageId: any;
  className = localStorage.getItem("themeName");
  drop(event: CdkDragDrop<string[]>) {
    ////console.log(
    //   "dropped Event",
    //   `> dropped '${event.item.data}' into '${event.container.id}'`
    // );
    if (event.container.id == 'Available Contractors') {
      this.contractorStatus = true;
      this.contractorPayload = [
        {
          "email": event.item.data,
          "role": "CONT"

        }
      ]
      this.workActions();
    } else {
      this.contractorStatus = false;
      this.contractorPayload = [
        {
          "email": event.item.data,
          "role": "CONT"

        }
      ]
      this.workActions();
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  workActions() {
    this.selectedOrg = this.formDetailedGroup.value['AS_ORG_CD']
    this.organizationMaintainenceService.saveContractor(this.contractorStatus, this.selectedOrg, this.contractorPayload).subscribe(res => {
      ////console.log('save cont', res);
      if (!res.Action) {
        this.contractorStatus = false;
        this.contractorPayload = [];
        this.snackBar.open('Success', 'Ok', {
          duration: 2000,
          verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
          horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
          panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
        })
      } else {
        Swal.fire({
          text: res.Message,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    })
  }

  ngOnInit() {
    this.dataTransfer.myMethod(true);
    this.createForm();
    this.selectedOrgForm = this.formBuilder.group({
      addons: this.formBuilder.array([])
    });

    this.formDetailedGroup.valueChanges.subscribe(
      result => {
        console.log('result form', result);
        this.formDetailedStatus = true;
      })

    this.formCheckDetailedGroup.valueChanges.subscribe(
      result => {
        console.log('result form', result);
        this.formDetailedStatus = true;
      })

    this.formDocGroup.valueChanges.subscribe(
      result => {
        console.log('result form', result);
        this.formDocStatus = true;
      })
  }

  ngAfterViewInit(): void {
    this.dataSourceOrganization.sort = this.sort;
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'description': [null],
      'orgCode': [null],
      'telephone': [null],
      'city': [null],
      'externalkey': [null],
    });

    this.formDetailedGroup = this.formBuilder.group({
      'AS_ORG_DESC': ['', Validators.required],
      'AL_ORG_ACTIVE': [true],
      'AS_ORG_CD': ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      'AS_WR_EXTERNAL_KEY': [''],
      'AS_WR_STREETNUM': ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
      'AS_WR_STREETNAME': ['', Validators.required],
      'AS_WR_CITY': ['', Validators.required],
      'AS_WR_STATE': ['', [Validators.minLength(2), Validators.maxLength(2), Validators.required]],
      'AS_WR_ZIP': ['', [Validators.minLength(4), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
      'AS_WR_PHONE': ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
      'WR_EMAIL': ['', [Validators.pattern(this.emailregex), Validators.required]],
      'CLASSIFICATION_CD': ['',Validators.required],
      'TYPE_CD': [''],
      'LICENSE': [''],
      'LICENSEEXP': ['',Validators.required],
      'LICENSENAME': [''],
      'NOTES': [''],
    });


    this.formCheckDetailedGroup = this.formBuilder.group({
      'AS_PAYEE_FULLNAME': ['', Validators.required],
      'AS_PAYEE_STREETNUM': ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
      'AS_PAYEE_STREETNAME': ['', Validators.required],
      'AS_PAYEE_CITY': ['', Validators.required],
      'AS_PAYEE_STATE': ['', [Validators.minLength(2), Validators.maxLength(2), Validators.required]],
      'AS_PAYEE_ZIP': ['', [Validators.minLength(4), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
      'AS_PAYEE_ADDRESS1': [''],
      'AS_PAYEE_ADDRESS2': [''],
    });

    this.formDocGroup = this.formBuilder.group({
      "DESCRIPTION": [''],
      "ACTIVE": [true],
      "TITLE": [''],
      "SYSTEMIMAGES_ID": ['']
    });

    this.formAccredGroup = this.formBuilder.group({
      "CLASSIFICATION": ['',Validators.required],
      "LICENCENUMBER":['',Validators.required],
      "ISSUED":['',Validators.required],
      "EXPIRES":['',Validators.required],
      "STATE":[''],
      "SPECIALTY":['']
    });

  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent.index-----', tabChangeEvent)
    this.selectedIndex = tabChangeEvent.index;
    if (this.selectedIndex == 0 || this.selectedIndex == 2) {
      this.hideSave = false;
    } else {
      this.hideSave = true;
    }
    // if (this.formStatus) {
    //   console.log('test')
    //   // Swal.fire({
    //   //   title: 'Are you sure?',
    //   //   text: "There are some unsaved changes ",
    //   //   icon: 'warning',
    //   //   confirmButtonText: 'Yes, discard it!',
    //   //   showCancelButton: true,
    //   // }).then((result) => {
    //   //   if (result.value) {
    //   //     Swal.fire(
    //   //       'Discarded!',
    //   //       'Your changes are discarded.',
    //   //       'success'
    //   //     )
    //   //   }
    //   // })
    // } else {

    // }

  }

    // Accreditations Tab ////////////////////////////////////////////////////////////////////////////////////////

    addAccreditations(){
      this.addOrEditText = 'Add New';
      this.editAccred = true; 
      this.addAccred = true; 
    }
  
    clearAccred(){
      
      
      if(this.formAccredGroup.dirty){
  
        Swal.fire({
          title: 'Are you sure?',
          text: "There are some unsaved changes ",
          icon: 'warning',
          confirmButtonText: 'Yes, discard it!',
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Discarded!',
              'Your changes are discarded.',
              'success'
            )
            this.formAccredGroup.reset();
            this.editAccred = false;
            this.oldAccreditation = null;
          } else {
    
          }
        })
      }
      else{
        if(!this.formAccredGroup.valid){
          this.formAccredGroup.markAllAsTouched();
        } else {
          this.editAccred = false;
          this.formAccredGroup.reset();
        }
      }
      
    }
  
    editaccreditations(data){
      this.oldAccreditation = data
      this.formAccredGroup.controls['CLASSIFICATION'].setValue(data['CLASSIFICATION']);
      this.formAccredGroup.controls['LICENCENUMBER'].setValue(data['LICENCENUMBER']);
      if(data['ISSUED'] != null){
        this.formAccredGroup.controls['ISSUED'].setValue(new Date(data['ISSUED'])); 
      }
      this.formAccredGroup.controls['EXPIRES'].setValue(new Date(data['EXPIRES']));
      this.formAccredGroup.controls['STATE'].setValue(data['STATE']);
      this.formAccredGroup.controls['SPECIALTY'].setValue(data['SPECIALTY']);
      
    
      this.addOrEditText = 'Edit ';
      this.addAccred=false;
      this.editAccred = true;
    }

    deleteaccreditations(data,i){

        Swal.fire({
          title: 'Are you sure?',
          text: "Delete this accreditation",
          icon: 'warning',
          confirmButtonText: 'Yes, delete it!',
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.organizationMaintainenceService.deleteAccreditation(this.selectedOrg, data).subscribe ( res=> {
            console.log(res);
              if(!res.Action){     
                this.organizationDetails.AccreditationsTab.splice(i,1);
                this.dataSourceAccreditations.data = this.organizationDetails.AccreditationsTab;              
            Swal.fire(
              'Deleted!',
              'Accreditation has been deleted.',
              'success'
            )
              } else {
                Swal.fire(
                  'Error!',
                    res.Message,
                  'warning'
                );
              }
            }, err =>{

            });
            
          }


      });
    }
  
    onAccreditationSubmit(){
     
      if(this.formAccredGroup.valid){
        this.newAccreditation = this.formAccredGroup.value;     
        this.newAccreditation.EXPIRES = format(new Date(this.formAccredGroup.value.EXPIRES), 'yyyy-MM-dd\'T\'00:00:00');        
        this.newAccreditation.ISSUED = format(new Date(this.formAccredGroup.value.ISSUED), 'yyyy-MM-dd\'T\'00:00:00');      
        if (this.addAccred){
          this.organizationMaintainenceService.addAccreditation(this.selectedOrg,this.newAccreditation).subscribe ( res => {
            if(!res.Action){     
              this.organizationDetails.AccreditationsTab.push(this.newAccreditation);
              this.editAccred = false;
              this.formAccredGroup.reset();
              Swal.fire(
                'Saved!',
                'Your Accreditation has been added.',
                'success'
              );
            } else{
              Swal.fire(
                'Error!',
                  res.Message,
                'warning'
              );
            }
          }, err => {
            
          });

        }
        else{
          if(this.organizationDetails.OrganizationTab.CLASSIFICATION_CD == this.formAccredGroup.value.CLASSIFICATION  && this.organizationDetails.OrganizationTab.LICENSEEXP == this.formAccredGroup.value.EXPIRES ){           
            this.formDetailedGroup.controls['LICENSE'].patchValue(this.formAccredGroup.value.LICENCENUMBER);            
          }     
          this.organizationMaintainenceService.editAccreditation(this.selectedOrg,this.newAccreditation).subscribe ( res => {
            if(!res.Action){  
                 
              for(let i = 0; i < this.organizationDetails.AccreditationsTab.length; i++){
                const obj = this.organizationDetails.AccreditationsTab[i];
                if(obj == this.oldAccreditation ){
                  this.organizationDetails.AccreditationsTab.splice(i,1);
                  this.organizationDetails.AccreditationsTab.push(this.newAccreditation);
                }
              }
              this.editAccred = false;
              this.formAccredGroup.reset();
              Swal.fire(
                'Saved!',
                'Your Accreditation has been edited.',
                'success'
              );
            } else{
              Swal.fire(
                'Error!',
                  res.Message,
                'warning'
              );
            }
          }, err => {
            
          });
        }

      }
      

      
  
    }

  sameAddress() {
    this.formCheckDetailedGroup.controls['AS_PAYEE_STREETNUM'].setValue(this.formDetailedGroup.controls['AS_WR_STREETNUM'].value);
    this.formCheckDetailedGroup.controls['AS_PAYEE_STREETNAME'].setValue(this.formDetailedGroup.controls['AS_WR_STREETNAME'].value);
    this.formCheckDetailedGroup.controls['AS_PAYEE_CITY'].setValue(this.formDetailedGroup.controls['AS_WR_CITY'].value);
    this.formCheckDetailedGroup.controls['AS_PAYEE_STATE'].setValue(this.formDetailedGroup.controls['AS_WR_STATE'].value);
    this.formCheckDetailedGroup.controls['AS_PAYEE_ZIP'].setValue(this.formDetailedGroup.controls['AS_WR_ZIP'].value);
  }

  doSearch() {
    this.isOrgSelected = true;
    this.selectedIndex = 0;
    this.organizationDetails = {}
  }

  doClear() {
    if (this.formDetailedStatus || this.formDetailedStatus) {
      // console.log('test')
      Swal.fire({
        title: 'Are you sure?',
        text: "There are some unsaved changes ",
        icon: 'warning',
        confirmButtonText: 'Yes, discard it!',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          if (!this.orgType) {
            this.formDetailedGroup.patchValue(this.organizationDetails.OrganizationTab)
            this.formCheckDetailedGroup.patchValue(this.organizationDetails.OrganizationTab)
          } else {
          this.formDetailedGroup.reset();
          this.formCheckDetailedGroup.reset();
          }
          Swal.fire(
            'Discarded!',
            'Your changes are discarded.',
            'success'
          )
        } else {

        }
      })
    }

    if (this.formDocStatus) {
      // console.log('test')
      Swal.fire({
        title: 'Are you sure?',
        text: "There are some unsaved changes ",
        icon: 'warning',
        confirmButtonText: 'Yes, discard it!',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          if (this.docSavetype) {
            this.formDocGroup.reset();
          }else {
            this.formDocGroup.patchValue(this.sysImageId);
          }
          Swal.fire(
            'Discarded!',
            'Your changes are discarded.',
            'success'
          )
        } else {

        }
      })
    }
  }

  doOrgClear() {
    this.formGroup.reset();
    this.dataSourceOrganization.data = [];
  }

  entered(event: CdkDragEnter<string[]>) {
    ////console.log('Entered', event.item);
  }

  exited(event: CdkDragExit<string[]>) {
    ////console.log('Exited', event.item);
  }

  doSave() {
    // console.log("licenseexp val = " + this.formDetailedGroup.controls['LICENSEEXP'].value);
    if (this.formDetailedGroup.controls['LICENSE'].valid 
      && this.formDetailedGroup.controls['LICENSE'].value !== ''
      && this.formDetailedGroup.controls['LICENSEEXP'].value == null) {
        Swal.fire({
          text: 'License expiration date required for license #',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }

    let mandat;
    this.formDetailedGroup.value['AL_ORG_ACTIVE'] = this.formDetailedGroup.value['AL_ORG_ACTIVE'] ? 1 : 0;
    if (!this.orgType) {
      this.selectedOrg = this.formDetailedGroup.value['AS_ORG_CD']
      mandat = this.organizationDetails.OrganizationTab
    } else {
      mandat = this.addOrgDetails
      // this.selectedOrg = 'NEW'
      this.selectedOrg = this.formDetailedGroup.value['AS_ORG_CD']
    }
    if (this.selectedIndex === 0) {
      const formdta = {
        "OrganizationTab": { ...mandat, ...this.formDetailedGroup.value, ...this.formCheckDetailedGroup.value }
      }
      const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass: this.className});
      this.organizationMaintainenceService.saveOrganization(this.orgType, this.selectedOrg, formdta).subscribe(res => {
        ////console.log('save org', res);
        dialogRef.close();
        if (!res.Action) {
          Swal.fire({
            text: 'Changes saved',
            icon: 'success',
            confirmButtonText: 'OK',
            });
          this.addAccred = false;
          // this.snackBar.open('Success', 'Ok', {
          //   duration: 2000,
          //   verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
          //   horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
          //   panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
          // })
          if(this.newOrgSel){
            this.newOrganization = {
              ORGANIZATION_CD: formdta.OrganizationTab.AS_ORG_CD,
              ORGANIZATION_DESC: formdta.OrganizationTab.AS_ORG_DESC,
              EXTERNALKEY: formdta.OrganizationTab.AS_WR_EXTERNAL_KEY,
              CITY: formdta.OrganizationTab.AS_WR_CITY,
              PHONE_NUMBER: formdta.OrganizationTab.AS_WR_PHONE,
  
            };
            this.handleClick(this.newOrganization);
            }         
          this.isOrgSelected = false;
          this.newOrgSel = false;
          if(this.formDetailedGroup.controls.LICENSE.dirty){
            if(!this.organizationDetails.isNullOrUndefined()){
              for(let i of this.organizationDetails?.AccreditationsTab){          
                if((i.EXPIRES == this.organizationDetails?.OrganizationTab?.LICENSEEXP) && (i.CLASSIFICATION == this.organizationDetails?.OrganizationTab?.CLASSIFICATION_CD)){
                  i['LICENCENUMBER'] = this.formDetailedGroup.controls.LICENSE.value;             
                  this.dataSourceAccreditations.data = this.organizationDetails.AccreditationsTab; 
                }                     
              }
            }
          }
          
          this.selectedOrg = this.formDetailedGroup.controls['AS_ORG_CD'].value;
          

        } else {
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
      })
    } else if (this.selectedIndex === 1) {

      // this.organizationMaintainenceService.saveContractor(this.contractorStatus, this.selectedOrg, this.contractorPayload).subscribe(res => {
      //   ////console.log('save cont', res);
      //   if (!res.Action) {
      //     this.contractorStatus = false;
      //     this.contractorPayload = [];
      //     this.snackBar.open('Success', 'Ok', {
      //       duration: 2000,
      //       verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      //       horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
      //       panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
      //     })
      //   } else {
      //     Swal.fire({
      //       text: res.Message,
      //       icon: 'error',
      //       confirmButtonText: 'OK',
      //     })
      //   }
      // })
      // this.contractors = Contractors;
      // this.organizationContractors = OrganizationContractors;

    } else if (this.selectedIndex === 2) {
      const result = this.organizationDetails.WorkorderTypesTab || [];
      const workOrd = result.map((val, index) => {
        val.SELECTED = val.SELECTED ? 1.0 : 0.0;
        return val;
      })
      const formdta = {
        "WorkorderTypesTab": workOrd
      }
      const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:this.className});
      this.organizationMaintainenceService.saveOrganization(this.orgType, this.selectedOrg, formdta).subscribe(res => {
        ////console.log('save typoes', res);
        dialogRef.close();
        if (!res.Action) {
          this.snackBar.open('Success', 'Ok', {
            duration: 2000,
            verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
            horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
            panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
          })
        } else {
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
      })
    } else if (this.selectedIndex === 3) {

    } else if (this.selectedIndex === 4) {

    }

  }

  sortCustData(sort: Sort) {
    const data = this.organization;
    if (!sort.active || sort.direction === '') {
      this.organization = [];
      this.dataSourceOrganization.data = data;
      return;
    }

    this.dataSourceOrganization.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ORGANIZATION_DESC': return this.compare(a.ORGANIZATION_DESC, b.ORGANIZATION_DESC, isAsc);
        case 'ORGANIZATION_CD': return this.compare(a.ORGANIZATION_CD, b.ORGANIZATION_CD, isAsc);
        case 'CITY': return this.compare(a.CITY, b.CITY, isAsc);
        case 'PHONE_NUMBER': return this.compare(a.PHONE_NUMBER, b.PHONE_NUMBER, isAsc);
        case 'EXTERNALKEY': return this.compare(a.EXTERNALKEY, b.EXTERNALKEY, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onOrganisationSubmit(data) {
    this.organizationSearching = true;
    let params = '';
    for (let [key, value] of Object.entries(data)) {
      if (value) params += `&${key}=${value}`;
      else params += `&${key}`
    }
    // this.organizationForm = data; .replace(/&\s*$/, "")
    this.organizationMaintainenceService.getOrganizationSearch(params).subscribe(res => {
      //console.log('ser org', res)
      this.organizationSearching = false;
      this.organization = res
      this.dataSourceOrganization.data = this.organization;
    }, err => {
      this.organizationSearching = false;
      //console.log('add org err', err)
      if (err == 'Incorrect response!') {
        Swal.fire({
          text: 'No Records Found',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } else {
        Swal.fire({
          text: err,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    });
  }

  singleClick(row) {
    this.isOrgSelected = true;
    this.orgSelection.isSelected(row)
  }

  addOrganisation() {
    this.orgType = true;
    this.isOrgSelected = false;
    this.addAccred = true;
    this.formDetailedGroup.get('AS_ORG_CD').enable();
    this.organizationMaintainenceService.addNewOrganization().subscribe(res => {
      //console.log('add org', res)
      this.organizationDetails = {};
      this.dataSourceAccreditations.data = this.organizationDetails?.AccreditationsTab;
      res['AL_ORG_ACTIVE'] = 1;
      this.newOrgSel = true;
      this.addOrgDetails = res;
      this.addNewOrg = res;
      const { CLASSIFICATIONS = [], TYPES = [], CLASSIFICATION_CD = '', TYPE_CD = '' } = res || {}
      this.typeOpt = TYPES;
      this.classificationOpt = CLASSIFICATIONS;
      this.formDetailedGroup.patchValue(res)
      this.formCheckDetailedGroup.patchValue(res)
    })
  }

  onDocumentsChange(event) {
    // ////console.log('onDocumentsChange event.checked ', event.checked);
    if (event.checked) {
      const activeDocs = this.organizationDetails.DocumentsTab.filter(function (doc) {
        return doc.ACTIVE == 1;
      });
      this.dataSourceDocuments.data = activeDocs;
    } else {
      this.dataSourceDocuments.data = this.organizationDetails.DocumentsTab;
    }
  }

  addDocument() {
    this.formDocGroup.reset()
    this.docType = true;
    this.docSavetype = true;
  }

  deleteZip(zip, i) {

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want delete it?",
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        // ////console.log('deleteZip', zip);
        const item = zip;
        this.organizationMaintainenceService.deleteZip(this.selectedOrg, zip).subscribe(res => {
          ////console.log('deletezip', res);
          if (!res.Action) {
            this.organizationDetails.ZipCodesTab.splice(i, 1);
            this.snackBar.open('Success', 'Ok', {
              duration: 2000,
              verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
              horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
              panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
            });
          } else {
            Swal.fire({
              text: res.Message,
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
      }
    })
  }

  checkFileExt(ext) {
    let extension;
    switch (ext) {//check image's extension
      case "data:image/jpeg;base64":
        extension = "jpeg";
        break;
      case "data:image/png;base64":
        extension = "png";
        break;
      case "data:application/vnd.ms-excel;base64":
        extension = "csv";
        break;
      case "data:application/pdf;base64":
        extension = "pdf";
        break;  
      default://should write cases for more images types
        extension = "jpg";
        break;
    }
    return extension;
  }

  addZipItem() {
    ////console.log('zip item', this.addZip);
    const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:this.className});
    this.organizationMaintainenceService.saveZip(this.selectedOrg, [this.addZip]).subscribe(res => {
      ////console.log('addzip', res);
      dialogRef.close();
      if (!res.Action) {
        this.organizationDetails.ZipCodesTab.push(this.addZip)
        this.addZip = '';
        this.snackBar.open('Success', 'Ok', {
          duration: 2000,
          verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
          horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
          panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
        });
      } else {
        Swal.fire({
          text: res.Message,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }

    })
  }

  exportZips() {
    this.exportUtilService.downloadFile(this.organizationDetails.ZipCodesTab, 'jsontocsv', this.selectedOrg)
  }

  importZips() {

  }

  openBase64InNewTab(data, mimeType) {
    var byteCharacters = atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: mimeType + ';base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  getMimeType(ext) {
    let extension;
    switch (ext) {//check image's extension
      case ".jpeg":
        extension = "image/jpeg";
        break;
      case ".jpg":
        extension = "image/jpg";
        break;  
      case ".png":
        extension = "image/png";
        break;
      case ".csv":
        extension = "application/vnd.ms-excel";
        break;
      case ".pdf":
        extension = "application/pdf";
        break;  
      case ".doc":
        extension = "application/doc";
        break; 
      case ".docx":
        extension = "application/docx";
        break; 
      default://should write cases for more images types
        extension = "image/jpeg";
        break;
    }
    return extension;
  }

  viewDoc(doc) {
    if (doc && doc.IMAGE_ID) {
      console.log('view doc', doc);
      this.organizationMaintainenceService.viewAttachDoc(this.selectedOrg, doc.IMAGE_ID).subscribe(res => {

        if (!res.Action) {
          this.openBase64InNewTab(res.Content, this.getMimeType(doc.IMAGEFILENAME.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)));
        } else {
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }

      })
    } else {
      Swal.fire({
        text: 'Attachment is not available',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
    }
  }

  deleteDoc(doc) {

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete it? ",
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        // Swal.fire(
        //   'Discarded!',
        //   'Your changes are discarded.',
        //   'success'
        // )
        ////console.log('deleteDoc', doc);
        this.organizationMaintainenceService.deleteDoc(this.selectedOrg, doc.SYSTEMIMAGES_ID).subscribe(res => {
          //console.log('deletedoc', res);
          if (res === null) {
            this.organizationDetails.DocumentsTab.forEach((obj) => {
              if (obj.SYSTEMIMAGES_ID === doc.SYSTEMIMAGES_ID) obj.ACTIVE = 0;
            });
            this.dataSourceDocuments.data = this.organizationDetails.DocumentsTab;
            this.snackBar.open('Success', 'Ok', {
              duration: 2000,
              verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
              horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
              panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
            });
          } else {
            Swal.fire({
              text: res.Message,
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
      }
    })
  }

  editDoc(doc) {
    this.docActionName = 'Update Document'
    this.sysImageId = doc;
    this.docType = true;
    this.docSavetype = false;
    this.formDocGroup.patchValue(doc);
  }

  updateDoc() {
    let doc = this.formDocGroup.value;
    const CONTENT = this.extractedFileData ? this.extractedFileData[1] : this.extractedFileData;
    doc['ACTIVE'] = doc.ACTIVE ? 1 : 0;
    if (this.docSavetype) {

      let result
      if (this.orgType && this.docSavetype) {
        result = [{
          "OFFICE_ID": this.addOrgDetails["AS_OFFICE_ID"],
          "TYPE_CD": this.formDetailedGroup.value['TYPE_CD'],
          "IMAGE_ID": "",
          "IMAGEFILENAME": this.fileName,
          "EXTERNALKEY": this.formDetailedGroup.value['AS_WR_EXTERNAL_KEY'],
          "LASTUPDATED": new Date().toISOString(),
          "DESCRIPTION": "",
          "USER_ID": this.utilityService.currentUtility.BBCSUserId,
          "SYSTEMIMAGES_ID": "",
          "ACTIVE": 0,
          "TITLE": "",
        }]
      } else if (!this.orgType && this.docSavetype) {
        result = [{
          "OFFICE_ID": this.formDetailedGroup.value["AS_OFFICE_ID"],
          "TYPE_CD": this.formDetailedGroup.value['TYPE_CD'],
          "IMAGE_ID": "",
          "IMAGEFILENAME": this.fileName,
          "EXTERNALKEY": this.formDetailedGroup.value['AS_WR_EXTERNAL_KEY'],
          "LASTUPDATED": new Date().toISOString(),
          "DESCRIPTION": "",
          "USER_ID": this.utilityService.currentUtility.BBCSUserId,
          "SYSTEMIMAGES_ID": "",
          "ACTIVE": 0,
          "TITLE": "",
        }]
      } else {
        result = this.organizationDetails.DocumentsTab.filter(item => {
          return item.SYSTEMIMAGES_ID == doc.SYSTEMIMAGES_ID;
        })
      }

      let final = { ...result[0], ...doc }
      if (CONTENT) final = { ...final, CONTENT }
      const payload = {
        "DocumentsTab": [final]
      }
      //console.log(' this.formDocGroup update', payload)
      const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:this.className});
      this.organizationMaintainenceService.updateDoc(this.selectedOrg, payload).subscribe(res => {
        ////console.log('addzip', res);
        dialogRef.close();
        if (!res.Action) {
          this.organizationDetails.DocumentsTab.push(final)
          this.snackBar.open('Success', 'Ok', {
            duration: 2000,
            verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
            horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
            panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
          });
        } else {
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }

      })

    } else {
      //existing doc update
      const result = this.organizationDetails.DocumentsTab.filter(item => {
        return item.SYSTEMIMAGES_ID == doc.SYSTEMIMAGES_ID;
      })
      if (this.fileName) result[0].IMAGEFILENAME = this.fileName;
      let final = { ...result[0], ...doc }
      if (CONTENT) final = { ...final, CONTENT }
      const payload = {
        "DocumentsTab": [final]
      }
      //console.log(' this.formDocGroup update', payload)
      const dialogRef = this.dialog.open(WaitDialogComponent, {data: {caption: 'Saving...'},panelClass:this.className});
      this.organizationMaintainenceService.updateDoc(this.selectedOrg, payload).subscribe(res => {
        ////console.log('addzip', res);
        dialogRef.close();
        if (!res.Action) {

          this.organizationDetails.DocumentsTab.forEach((obj) => {
            if (obj.SYSTEMIMAGES_ID === doc.SYSTEMIMAGES_ID) obj = final;
          });
          this.dataSourceDocuments.data = this.organizationDetails.DocumentsTab;
          this.snackBar.open('Success', 'Ok', {
            duration: 2000,
            verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
            horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
            panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
          });
          this.fileName = '';
          this.extractedFileData = '';
        } else {
          Swal.fire({
            text: res.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }

      })
    }
  }

  closeDocUpdate() {
    this.docType = false;
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onUploadClicked(event) {
    this.fileName = event[0].name;
    //console.log('event', event)
  }

  onSelectedFilesChanged(event) {
    //console.log('event jj', event[0])
    this.fileName = event[0].name;
    //const base64 = this.getBase64(event[0])
    this.getBase64(event[0]).then(
      (data: string) => {
        //console.log('vadsafdsaf', data.split(','))
        this.extractedFileData = data.split(',')
      }
    );
    ////console.log('event base64', result['__zone_symbol__value'])
  }

  onZipUploadClicked(event) {
    ////console.log('event jj', event[0])
    this.fileName = event[0].name;
    //const base64 = this.getBase64(event[0])
    this.getBase64(event[0]).then(
      (data: string) => {
        ////console.log('vadsafdsaf', data.split(','))
        this.extractedFileData = data.split(',')
        if (this.checkFileExt(this.extractedFileData[0]) == 'csv') {
          const temp = window.atob(this.extractedFileData[1])
          const arr = temp.split('\n')
          arr.pop();
          arr.splice(0, 1)
          ////console.log('zip item', this.addZip);
          this.organizationMaintainenceService.saveZip(this.selectedOrg, arr).subscribe(res => {
            ////console.log('addzip', res);
            if (!res.Action) {
              this.organizationDetails.ZipCodesTab = [...this.organizationDetails.ZipCodesTab, ...arr];
              this.addZip = '';
              this.snackBar.open('Success', 'Ok', {
                duration: 2000,
                verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
                horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['mat-snack-bar-container', 'mat-snack-bar-action']
              });
              this.extractedFileData = '';
              this.fileName = '';
            } else {
              Swal.fire({
                text: res.Message,
                icon: 'error',
                confirmButtonText: 'OK',
              })
            }

          })
        } else {
          Swal.fire({
            text: 'Invalid file.Please upload csv filetype',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
      }
    );
  }

  onZipSelectedFilesChanged(event) {
    // //console.log('event jj', event[0])

    // //const base64 = this.getBase64(event[0])
    // this.getBase64(event[0]).then(
    //   (data: string) => {
    //     //console.log('vadsafdsaf', data.split(','))
    //     this.extractedFileData = data.split(',')
    //   }
    // );
    ////console.log('event base64', result['__zone_symbol__value'])
  }


  onSelectAll(e: any): void {
    for (let i = 0; i < this.organizationDetails.WorkorderTypesTab.length; i++) {
      const item = this.organizationDetails.WorkorderTypesTab[i];
      item.SELECTED = e;
    }
  }


  handleClick(org: OrganizationSearch) {
    //console.log('dblclik', org);
    if (org.ORGANIZATION_CD) {
      
      this.orgType = false;
      this.selectedOrg = org.ORGANIZATION_CD;
      this.organizationMaintainenceService.getSelectedOrganizationDetails(this.selectedOrg).subscribe(res => {
        console.log('details---', res)
        this.isOrgSelected = false;
        this.organizationDetails = res
        const { UsersTab: { OrganizationContractors = [], Contractors = [] } } = this.organizationDetails || {};
        const { CLASSIFICATIONS = [], TYPES = [], CLASSIFICATION_CD = '', TYPE_CD = '' } = this.organizationDetails.OrganizationTab || {}
        this.typeOpt = TYPES;
        this.classificationOpt = CLASSIFICATIONS;
        this.dataSourceAccreditations.data = this.organizationDetails.AccreditationsTab;
        this.dataSourceDocuments.data = this.organizationDetails.DocumentsTab;
        this.formDetailedGroup.patchValue(this.organizationDetails.OrganizationTab)
        this.formCheckDetailedGroup.patchValue(this.organizationDetails.OrganizationTab)
        this.contractors = Contractors;
        this.organizationContractors = OrganizationContractors;
        this.addAccred= false;      
        this.formDetailedGroup.get('AS_ORG_CD').disable();
        this.formDetailedGroup.setValue({
          'CLASSIFICATION_CD': CLASSIFICATION_CD,
          'TYPE_CD': TYPE_CD
        });
      }, err => {
        this.isOrgSelected = true;
        Swal.fire({
          text: err,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      });
    } else {
      this.isOrgSelected = true;
      Swal.fire({
        text: "No organization code for the selected",
        icon: 'warning',
        confirmButtonText: 'OK',
      })
    }
  }

}
