import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { DistributorService } from "src/app/services/distributor.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-stores",
  templateUrl: "./stores.component.html",
  styleUrls: ["./stores.component.scss"],
})
export class StoresComponent implements OnInit {
  stNo = new UntypedFormControl("", [Validators.required]);

  stCity = new UntypedFormControl("", [Validators.required]);
  stSName = new UntypedFormControl("");
  stSNum = new UntypedFormControl("");
  stState = new UntypedFormControl("", [
    Validators.required,
    Validators.maxLength(2),
  ]);
  stDistrict = new UntypedFormControl("", [Validators.required]);
  popupLabel = "Add Store";
  addressId: any;
  districtId: any;
  districts: any;

  constructor(
    public dialogRef: MatDialogRef<StoresComponent>,
    public distributorService: DistributorService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.enableDisableControls();
  }

  ngOnInit(): void {
    this.districts = this.distributorService?.indDetail?.StoresDetails[0]?.Individuals?.Districts;
    // Assigning first drop down value as default
    this.districtId = this.districts?.length > 0 ? this.districts[0]?.Id : null;

    if (this.data.editMode) {
      this.popupLabel = "Edit Store";
      this.stNo.setValue(
        this.distributorService.indDetail.StoresDetails[this.data.index]
          .StoreNumber
      );
      this.stCity.setValue(
        this.distributorService.indDetail.StoresDetails[this.data.index].City
      );
      this.stState.setValue(
        this.distributorService.indDetail.StoresDetails[this.data.index].State
      );
      this.stSName.setValue(
        this.distributorService.indDetail.StoresDetails[this.data.index].StreetName
      );
      this.stSNum.setValue(
        this.distributorService.indDetail.StoresDetails[this.data.index].StreetNumber
      );

      this.stDistrict.setValue(
        this.distributorService.indDetail.StoresDetails[this.data.index]
          .District
      );
      this.addressId =
        this.distributorService.indDetail.StoresDetails[
          this.data.index
        ].Addressid;
      this.districtId = this.distributorService?.indDetail?.StoresDetails[this.data.index]
      ?.Individuals.SelectedDistrictId
    }
  }

  changedDistrictID(data) {
    this.districtId = data;    
  }

  enableDisableControls() {
    this.data.editMode == true ? this.stNo.disable() : this.stNo.enable();
  }

  reset() {
    this.stNo.reset();
    this.stCity.reset();
    this.stDistrict.reset();
    this.stState.reset();
    this.stSName.reset();
    this.stSNum.reset();
  }

  badData() {
    return this.stNo.invalid || this.stCity.invalid || this.stState.invalid;
  }

  saveStore() {
    const store = {
      CompanyName: this.distributorService.selectedCompany,
      StoreNumber: this.stNo.value,
      CompanyAbbr: this.distributorService.selectedCompanyAbbr,
      City: this.stCity.value,
      State: this.stState.value,
      StreetName: this.stSName.value,
      StreetNumber: this.stSNum.value,
      Utility: this.distributorService.selectedUtility,
      District: this.districtId,
      Addressid: this.addressId ?? null,
    };
    this.spinner.show();
    this.distributorService
      .saveStore(store, this.distributorService.selectedDistributorCode)
      .subscribe((data) => {
        this.spinner.hide();
        if (data.Action) {
          Swal.fire({
            text: data.Message,
            icon: "success",
            confirmButtonText: "OK",
          });
          this.reset();
          this.dialogRef.close("1");
        } else
          Swal.fire({
            title: "Error",
            text: data.Message,
            icon: "error",
            confirmButtonText: "OK",
          });
      });
  }

  closedialog() {
    this.dialogRef.close();
  }
}
