import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UtilityService } from './utility.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class EnrollmentService {

    personcompanyid:any;
    private woSearchWsUrl1 = environment.apiUrl + 'workorder/';
    private saveRebateUrl = environment.apiUrl;
    private ownOrRentUrl = environment.apiUrl;
    constructor(
        private http: HttpClient,
        private utilityService: UtilityService,
        private userService: UserService
    ) {

    }

    currentEnrollmentID: string;
    
    private getHeaders() {
        const email = localStorage.getItem('loggedInUserEmail');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-email': email,
                'x-programsponsorid': this.utilityService.currentUtility.ProgramsponsorId
            })
        };
        return httpOptions;
    }


    public getQuestionsByPersonCompanyId(personcompanyId: string): Observable<any[]> {
        const searchUrl = environment.apiUrl
            + 'customer/eligibility/QuestionsResponses/'
            + this.utilityService.currentUtility.OfficeId
            + '/' + personcompanyId
            + '?code=' + environment.apiCode;

        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public getRecommendationsByPersonCompanyId(personcompanyId: string): Observable<any[]> {
        const searchUrl = environment.apiUrl
            + 'customer/eligibility/recommendations/'
            + this.utilityService.currentUtility.OfficeId
            + '/' + personcompanyId
            + '?code=' + environment.apiCode;

        return this.http.get<any[]>(searchUrl, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public saveQuestionsByPersonCompanyId(personcompanyId: string, elList): Observable<any[]> {
       
        const searchUrl = environment.apiUrl
            + 'customer/eligibility/saverecommendations/'
            + this.utilityService.currentUtility.OfficeId
            + '/' + personcompanyId
            + '?code=' + environment.apiCode;

     
      /*   const temp = [];
        const body = temp.concat(elList); */
        
        return this.http.post(searchUrl, elList, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }

    public enrollCustomer(personcompanyId: string, userId: string, programId: string, group: string): Observable<any> {
       console.log("Programdetaildr",programId);
        const searchUrl = environment.apiUrl
            + 'customer/eligibility/enrolldrcustomer/'
            + this.utilityService.currentUtility.OfficeId
            + '/' + personcompanyId
            + '/Group/' + group
            + '?code=' + environment.apiCode;
        let bbcsUserId = this.userService.dbBBCSId === '' ? userId : this.userService.dbBBCSId;
        const body = {
            UserId: bbcsUserId,
            ProgramdetailId: programId,
            org_cd: this.userService.orgCd
        };
        console.log('body',body);
        return this.http.post(searchUrl, body, this.getHeaders())
            .pipe(map(response => response),
                catchError(err => this.utilityService.handleError(err, this, []))
            );
    }
    public getProgramTypes(): Observable<any> {
        this.personcompanyid = localStorage.getItem('personcompanyid');
        console.log('personCompanyId', this.personcompanyid );
        const searchUrl = environment.apiUrl
         + 'workorder/'
         + this.utilityService.currentUtility.OfficeId
         + '/individual'+'/eligibility' +'/ALL' 
          + '?code='+ environment.apiCode + '&PersoncompanyId=' + this.personcompanyid + '&org_cd=' + this.userService.orgCd;

        return this.http.get<any>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
            catchError(async (err) => console.log(err))
        );
    }
    //to get the Qualification questions for program enrollment
    getIndividualQuestions(personcompanyid, ProgramdetailId):Observable<any>{
        const searchUrl= environment.apiUrl+ "customer/individualeligibility/"+ this.utilityService.currentUtility.OfficeId+"/"+personcompanyid+"/programdetail/"+ProgramdetailId+"?code="+environment.apiCode;
        console.log(searchUrl);
        return this.http.get<any[]>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
        catchError(async (err) => console.log(err))
        );
      }

      public verifyIndividualEligibilityQuestions(personCompanyId:any, ProgramdetailId:any):Observable<any> {    
        const searchUrl = environment.apiUrl + "customer/verifyeligibility/" +  this.utilityService.currentUtility.OfficeId
          + "/" + personCompanyId +"/programdetail/"+ ProgramdetailId +"?code="+ environment.apiCode;
      
        return this.http.get<any>(searchUrl, this.getHeaders())
          .pipe(map(response => response),
          catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
          );
      }

      public saveIndividualQuestionsByPersonCompanyId(personcompanyId: string, elList,programDetailId:string,group:string): Observable<any> {
           const searchUrl = environment.apiUrl
           + 'customer/eligibility/updateeligibility/'
           +  this.utilityService.currentUtility.OfficeId
           + '/' + personcompanyId
           + '/' + programDetailId
           + '/Group/' + group
           + '?code=' + environment.apiCode;
             
       return this.http.post(searchUrl, elList, this.getHeaders())
           .pipe(map(response => response),
           catchError(async (err) => console.log(err))
           );
     }

     public enrollJcplCustomer(personcompanyId: string, userId: string, programDetailId: string, agreeTerms: any, group: any, landlordconsent: any): Observable<any> {
        const searchUrl = environment.apiUrl
          + 'customer/eligibility/enrolldrcustomer/'
          + this.utilityService.currentUtility.OfficeId
          + '/' + personcompanyId
          + '/Group/' + group
          + '?code=' + environment.apiCode;
    
        const body = {
          UserId: userId,
          PersonCompanyId: personcompanyId,
          ProgramdetailId: programDetailId,
          TermsConditions: agreeTerms,
          Group: group,
          LandLordConsent: landlordconsent,
        };
    
        return this.http.post(searchUrl, body, this.getHeaders())
          .pipe(map(response => response),
            catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
          );
      }  

      public enrollJcpCustomer(personcompanyId: string, userId: string, programDetailId: string, agreeTerms: any, group: any, landlordconsent: any): Observable<any> {
        const searchUrl = environment.apiUrl
          + 'customer/eligibility/enrolldrcustomer/'
          + this.utilityService.currentUtility.OfficeId
          + '/' + personcompanyId
          + '/Group/' + group
          + '?code=' + environment.apiCode;
    
        const body = {
          UserId: userId,
          PersonCompanyId: personcompanyId,
          ProgramdetailId: programDetailId,
          TermsConditions: agreeTerms,
          Group: group,
          LandLordConsent: landlordconsent,
        };
    
        return this.http.post(searchUrl, body, this.getHeaders())
          .pipe(map(response => response),
            catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
          );
      }


       //To upload file content
  uploadFile(contentBody,workorderID): Observable<any[]> {
    console.log("uploadFile");
    const searchUrl = this.woSearchWsUrl1 + "documents/upload/" + this.utilityService.currentUtility.OfficeId
      + '/id/' + workorderID + '?code=' + environment.apiCode;
    const body = contentBody;
    console.log("SearchUrl",searchUrl );
    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
   }

    //SAVE REBATE WORKORDER
    saveRebateWorkOrder(workorderID,userId,isEP): Observable<any[]> {
    
        const searchUrl = this.saveRebateUrl + "rebate/"+ this.utilityService.currentUtility.OfficeId+"/workorder/" + workorderID + "/user/" + userId +"/"+ isEP+'?code=' + environment.apiCode;
        
        const body = "save";
        return this.http.post<any>(searchUrl,body,this.getHeaders())
          .pipe(map(response => response),
            catchError(err => this.utilityService.handleError(err, this, []))
          );
      }

    //ownOrRent

    ownorrent(officeId,personCompanyId,bodyContent): Observable<any> {
  
      
      const searchUrl = this.ownOrRentUrl + "customer/" + officeId + "/" + personCompanyId
        +  '?code=' + environment.apiCode; 
        
      return this.http.patch<any>(searchUrl, bodyContent, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => this.utilityService.handleError(err, this, []))
        );
    
    
     }
   

}
